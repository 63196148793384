import actions from "./actions";

const initState = {
  allDestinationDetails: [],
  addDestinationSuccess: false,
  allDepartureCount: "",
  singleDestinationDetail: {},
  addDepartureSuccess: false,
  addDepartureDetails: [],
  singleDepartureDetail: {},
  addPinSuccess: false,
  addPinsDetails: [],
  editPinSuccess: false,
  singlePinDetail: {},
  deletePinSuccess: false,
  loading: false,
};

const allDestinationDataModifier = (dataWithCount) => {
  const allDestinationData = [];
  let number = 1;
  dataWithCount.forEach((destination) => {
    let singleDestination = {
      number: ("000" + number).slice(-3),
      routeName: destination.route_name,
      destinationLocalName: destination.destination_japan,
      destinationEnglishName: destination.destination_english,
      details: ("000" + number).slice(-3),
      key: destination.id,
      departureCount: destination?.departurelength || 0,
      creationdate: destination.creationdate,
    };
    allDestinationData.push(singleDestination);
    number = number + 1;
  })
  // data.data.forEach((item, index) => {
  //   const departureCount = dataWithCount.filter((dep)=> dep.id === item.id)
  //   let singleDestination = {
  //     number: ("000" + number).slice(-3),
  //     routeName: item.data().route_name,
  //     destinationLocalName: item.data().destination_japan,
  //     destinationEnglishName: item.data().destination_english,
  //     details: ("000" + number).slice(-3),
  //     key: item.id,
  //     departureCount: departureCount[0]?.departurelength || 0,
  //     creationdate: item.data().creationdate,
  //   };
  //   allDestinationData.push(singleDestination);
  //   number = number + 1;
  // });
  return allDestinationData.sort(function (a, b) {
    return new Date(b.creationdate) - new Date(a.creationdate);
  });
};

const allDepartureDataModifier = (data) => {
  const allDepartureData = [];
  data.data.forEach((item, index) => {
    console.log('item', item);
    let singleDeparture = {
      creationdate: item.data().creationdate,
      departure_point: `${item.data().departure_point_english}/${item.data().departure_point
        }`,
      transportation: item.data().transpotation_mode,
      zoomlevel: item.data().zoomlevel,
      key: item.id,
      exitLandmark: `${item.data().exit_landmark_english}/${item.data().exit_landmark_local}`,
    };
    allDepartureData.push(singleDeparture);
  });
  return allDepartureData;
};

const allPinDataModifier = (data) => {
  const allPinData = [];
  data.data.forEach((item, index) => {
    let singlePin = {
      key: item.id,
      routeinfo_id: item.data().routeinfo_id,
      pin: item.data().pin,
      orientation: item.data().orientation,
      image: item.data().image,
      pin_lat: item.data().pin_lat,
      pin_lng: item.data().pin_lng,
      zoomlevel: item.data().zoomlevel,
      creationdate: item.data()?.creationdate,
    };
    allPinData.push(singlePin);
  });
  return allPinData.sort(function (a, b) {
    return new Date(a.creationdate) - new Date(b.creationdate);
  });
};

export default function adminReducer(state = initState, action) {
  switch (action.type) {
    case actions.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actions.ADD_DESTINATION:
      return {
        ...state,
      };
    case actions.ADD_DESTINATION_SUCCESS:
      return {
        ...state,
        addDestinationSuccess: true,
      };
    case actions.ADD_DESTINATION_FAILED:
      return {
        ...state,
        addDestinationSuccess: false,
      };

    case actions.EDIT_DESTINATION:
      return {
        ...state,
      };
    case actions.EDIT_DESTINATION_SUCCESS:
      return {
        ...state,
        addDestinationSuccess: true,
      };
    case actions.EDIT_DESTINATION_FAILED:
      return {
        ...state,
        addDestinationSuccess: false,
      };

    case actions.GET_ALL_DESTINATION:
      return {
        ...state,
        addDestinationSuccess: false,
      };
    case actions.GET_ALL_DESTINATION_SUCCESS:
      const allDestinationData = allDestinationDataModifier(
        // action.allDestinationDetails,
        action.allDestinationDetailsWithCount
      );
      return {
        ...state,
        allDestinationDetails: allDestinationData,
      };
    case actions.GET_ALL_DESTINATION_FAILED:
      return {
        ...state,
      };
    case actions.GET_ALL_DEPARTURE_COUNT:
      return {
        ...state,
      };

    case actions.GET_ALL_DEPARTURE_COUNT_SUCCESS:
      return {
        ...state,
        allDepartureCount: action.allDepartureCount,
      };
    case actions.GET_ALL_DEPARTURE_COUNT_FAILED:
      return {
        ...state,
      };

    case actions.DELETE_DESTINATION:
      return {
        ...state,
      };
    case actions.DELETE_DESTINATION_SUCCESS:
      return {
        ...state,
      };
    case actions.DELETE_DESTINATION_FAILED:
      return {
        ...state,
      };

    case actions.GET_SINGLE_DESTINATION:
      return {
        ...state,
      };
    case actions.GET_SINGLE_DESTINATION_SUCCESS:
      // console.log({action});
      return {
        ...state,
        singleDestinationDetail: action.singleDestinationDetail,
      };
    case actions.GET_SINGLE_DESTINATION_FAILED:
      return {
        ...state,
      };

    case actions.ADD_DEPARTURE:
      return {
        ...state,
      };
    case actions.ADD_DEPARTURE_SUCCESS:
      return {
        ...state,
        addDepartureSuccess: true,
      };
    case actions.ADD_DEPARTURE_FAILED:
      return {
        ...state,
        addDepartureSuccess: false,
      };

    case actions.EDIT_DEPARTURE:
      return {
        ...state,
      };
    case actions.EDIT_DEPARTURE_SUCCESS:
      return {
        ...state,
        addDepartureSuccess: true,
      };
    case actions.EDIT_DEPARTURE_FAILED:
      return {
        ...state,
        addDepartureSuccess: false,
      };

    case actions.GET_ALL_DEPARTURE:
      return {
        ...state,
        addDepartureSuccess: false,
      };
    case actions.GET_ALL_DEPARTURE_SUCCESS:
      const allDepartureData = allDepartureDataModifier(
        action.allDepartureDetails
      );
      return {
        ...state,
        addDepartureDetails: allDepartureData,
      };
    case actions.GET_ALL_DEPARTURE_FAILED:
      return {
        ...state,
      };

    case actions.DELETE_DEPARTURE:
      return {
        ...state,
      };
    case actions.DELETE_DEPARTURE_SUCCESS:
      return {
        ...state,
      };
    case actions.DELETE_DEPARTURE_FAILED:
      return {
        ...state,
      };

    case actions.GET_SINGLE_DEPARTURE:
      return {
        ...state,
      };
    case actions.GET_SINGLE_DEPARTURE_SUCCESS:
      return {
        ...state,
        singleDepartureDetail: action.singleDepartureDetail,
      };
    case actions.GET_SINGLE_DEPARTURE_FAILED:
      return {
        ...state,
      };

    case actions.ADD_PIN:
      return {
        ...state,
      };
    case actions.ADD_PIN_SUCCESS:
      return {
        ...state,
        addPinSuccess: true,
      };
    case actions.ADD_PIN_FAILED:
      return {
        ...state,
        addPinSuccess: false,
      };

    case actions.EDIT_PIN:
      return {
        ...state,
      };
    case actions.EDIT_PIN_SUCCESS:
      return {
        ...state,
        editPinSuccess: true,
      };
    case actions.EDIT_PIN_FAILED:
      return {
        ...state,
        editPinSuccess: false,
      };

    case actions.GET_ALL_PIN:
      return {
        ...state,
        addPinSuccess: true,
      };
    case actions.GET_ALL_PIN_SUCCESS:
      const allPinData = allPinDataModifier(action.allPinDetails);
      return {
        ...state,
        addPinsDetails: allPinData,
      };
    case actions.GET_ALL_PIN_FAILED:
      return {
        ...state,
      };

    case actions.DELETE_PIN:
      return {
        ...state,
      };
    case actions.DELETE_PIN_SUCCESS:
      return {
        ...state,
        deletePinSuccess: true,
      };
    case actions.DELETE_PIN_FAILED:
      return {
        ...state,
        deletePinSuccess: false,
      };

    case actions.GET_SINGLE_PIN:
      return {
        ...state,
      };
    case actions.GET_SINGLE_PIN_SUCCESS:
      // console.log('single pin',action.singlePinDetail);
      return {
        ...state,
        singlePinDetail: action.singlePinDetail,
      };
    case actions.GET_SINGLE_PIN_FAILED:
      return {
        ...state,
      };

    case actions.CLEAR_DEPARTURE_DATA:
      return {
        ...state,
        singleDepartureDetail: {}
      };
    case actions.CLEAR_DATA:
      return {
        ...state,
        addDestinationSuccess: false,
        // singleDepartureDetail:{}
      };

    default:
      return state;
  }
}
