import { app } from '../db/firebaseConnection'
import { Destination } from '../models/destination'
import { doc, getFirestore, deleteDoc, where, getDocs, query, collectionGroup, getDoc } from 'firebase/firestore'
import { getStorage, ref, deleteObject } from 'firebase/storage'
const db = getFirestore(app)

export const deleteDestination = async (id) => {
  try {
    console.log(id, 'id')
    console.log('deleteDestination Destination')
    return new Promise((resolve, reject) => {
      for (let i = 0; i < id.length; i++) {
        const destRef = doc(db, 'Destination', id[i])
        deleteDoc(destRef)
          .then((data) => {
            console.log('Data Deleted Successfully')
            // resolve({
            //   error: false,
            //   Status: 200,
            //   message: "Record deleted successfully",
            //   data: data,
            // });
            const routes = query(collectionGroup(db, 'Route'), where('destination_id', '==', id[i]))
            getDocs(routes).then(async (data) => {
              console.log('Departure point Fetched Successfully', data)
              data.forEach(async (docs) => {
                console.log(docs.id, ' => ', docs.data().destination_id)
                const routeRef = doc(db, 'Route', docs.id)
                const pins = query(collectionGroup(db, 'Pin'), where('routeinfo_id', '==', docs.id))
                getDocs(pins).then(async (data) => {
                  data.forEach(async (docs) => {
                    const pinRef = doc(db, 'Pin', docs.id)
                    const snapshot = await getDoc(pinRef)
                    const path = snapshot.data().storagePath
                    console.log(path)
                    const storage = getStorage()
                    const deleteRef = ref(storage, path)
                    await deleteObject(deleteRef)
                    await deleteDoc(pinRef)
                    await deleteDoc(routeRef)
                  })
                })
              })
            })
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            console.log('Document Deletion Failure!!!', errorCode, errorMessage)
            resolve({
              error: false,
              Status: 401,
              message: 'Document deletion failed',
              data: errorCode,
            })
          })
      }
      resolve({
        error: false,
        Status: 200,
        message: 'Records deleted successfully',
        data: '',
      })
    })
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: 'Internal Server Error',
      data: error.message,
    }
  }
}
