import styled from 'styled-components';
import WithDirection from '../../library/helpers/rtl';
import { variables } from '../../assets/styles/variables';

const ViewRouteDetailsStyleWrapper = styled.div`
  .add-destination-save {
    display: flex;
    width: 70%;
    margin: 0px auto;
    .ant-btn {
      height: auto;
      min-height: 50px;
      width: 100%;
      white-space: normal;
    }
  }
  // .content-container{
  //   max-height: 66vh;
  //   overflow-y:auto;
  // }
  .header-btn{
    display: flex;
    justify-content: center;
  }
  .destination-info{
    height: 150px;
    margin-top: 20px;
    overflow: auto;
    border:box;
    div{
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      p {
        min-width: 50%;
        margin: 0px 10px 0px 0px;
        align-self: center;
      }
    }
  }
  .destination-border{
    padding: 1%;
    border: 1px solid ${variables.sub_color_light_grey};
    margin-top: 20px;
    width: 100%
  }
  .route-details-card{
    // min-height: 70vh;
    // max-height: 70vh;
  }
  .add-destination-form-container {
    margin-top: 20px;
    padding: 0px 20px;
    max-height: 25%;
    overflow-y: auto;
    div {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      p {
        min-width: 50%;
        margin: 0px 10px 0px 0px;
        align-self: center;
      }
    }
  }
  .view-route-right-wrapper {
    display: flex;
    justify-content: space-between !important;
    flex-direction: column;
    padding: 1%;
    border: 1px solid ${variables.sub_color_light_grey};
    .ant-table-wrapper {
      width: 100%;
      th,
      td {
        text-align: center;
        .ant-btn{
          margin: 0px 0px 0px 20px;
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: space-between !important;
      margin: 10px 0;
      .ant-btn {
        margin: 0px 10px;
      }
    }
    .addDeparturePoint {
      margin-top: 150px;
      min-height: 50px;
      height: auto;
      white-space: normal;
      width: 40%;
      align-self: center;
    }
  }
`;
export default WithDirection(ViewRouteDetailsStyleWrapper);
