import { app } from "../db/firebaseConnection";
import { Route } from "../models/route";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
const db = getFirestore(app);
export const updateDeparturepoint = async (id, data) => {
  try {
    return new Promise((resolve, reject) => {
      const newCityRef = doc(db, "Route", id);
      updateDoc(newCityRef, data)
        .then((data) => {
          resolve({
            error: false,
            Status: 200,
            message: "Departure point updated successfully",
            data: data,
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          resolve({
            error: false,
            Status: 401,
            message: "Departure point updation failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
