import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Card, Input, Select } from "antd";
import { AdminLayoutStyleWrapper1 } from "../../assets/styles/globalStyle";
import MapContainer from "../../components/MapContainer/MapContainer";
import RouteTitleContainer from "../../components/RouteTitleContainer/RouteTitleContainer";
import AddDepaturePointStyleWrapper from "./AddDepaturePoint.styles";
import FooterButton from "../../components/FooterButton/FooterButton";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/admin/actions";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AppConstant } from "../../config/constant";
import { usePlacesWidget } from "react-google-autocomplete";

import {
  TITLE_TRANSPORTATION,
  TITLE_SEARCH_DEPARTURE,
  TITLE_ZIPCODE,
  TITLE_ADDRESS,
  TITLE_ROUTE_INFO,
  BUTTON_SAVE_ROUTE,
  BUTTON_ADD_PIN,
  TITLE_DEPARTURE_POINT_NAME,
  TITLE_DEPARTURE_POINT_NAME_EN,
  TITLE_EXIT,
  TITLE_EXIT_EN,
  BUTTON_SAVE,
  PLACEHOLDER_TRANSPORTATION,
  PLACEHOLDER_SEARCH_DEPARTURE,
  PLACEHOLDER_ZIPCODE,
  PLACEHOLDER_ADDRESS,
  PLACEHOLDER_ROUTE_INFO,
  PLACEHOLDER_DEPARTURE_POINT_NAME,
  PLACEHOLDER_DEPARTURE_POINT_NAME_EN,
  PLACEHOLDER_EXIT,
  PLACEHOLDER_EXIT_EN,
} from "../../config/constant/language";
import { ThreeDots } from "react-loader-spinner";

export default function AddDepaturePoint() {
  const antInputRef = useRef(null);
  const { id } = useParams();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { getallpin } = actions;
  const {
    cleardata,
    getdestination,
    adddeparture,
    editdeparture,
    getdeparture,
  } = actions;
  const [originLatLng, setOriginLatLng] = useState({ lat: 0, lng: 0 });
  const [destinationLatLng, setDestinationLatLng] = useState({
    lat: 0,
    lng: 0,
  });
  const [addDepartureForm] = Form.useForm();
  let location = useLocation();

  const navigate = useNavigate();
  let addDepartureSuccess = useSelector(
    (state) => state.Admin.addDepartureSuccess
  );

  let singleDestinationDetail = useSelector(
    (state) => state.Admin.singleDestinationDetail
  );
  let singleDepartureDetail = useSelector(
    (state) => state.Admin.singleDepartureDetail
  );
  let addPinDetails = useSelector((state) => state.Admin.addPinsDetails);
  const [pathLtLg, setPathLtLg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDepartureDirection, setShowDepartureDirection] = useState(false);

  const dep_id = localStorage.getItem("departure_id");
  const des_id = localStorage.getItem("destination_id");
  useEffect(() => {
    if (dep_id !== undefined) {
      dispatch(getallpin(dep_id));
    }
    return ()=>{
      dispatch(cleardata())
    }
  }, []);

  useEffect(() => {
    if (singleDestinationDetail) {
      setDestinationLatLng({
        lat: parseFloat(singleDestinationDetail?.destination_lat),
        lng: parseFloat(singleDestinationDetail?.destination_lng),
      });
    }
    return ()=>{
      setDestinationLatLng({ lat: 0, lng: 0 });
    }
  }, [singleDestinationDetail?.destination_lat,singleDestinationDetail?.destination_lng]);

  useEffect(() => {
    if (singleDepartureDetail && singleDepartureDetail?.path_lat_long_array != undefined) {
      setPathLtLg(singleDepartureDetail?.path_lat_long_array);
      setOriginLatLng({
        lat: parseFloat(singleDepartureDetail.departure_lat),
        lng: parseFloat(singleDepartureDetail.departure_lng),
      });
      setLoading(true)
      if (location.pathname.includes("edit")) {
        setTimeout(()=>{
          setLoading(false)
        },1000)
        setShowDepartureDirection(true)
        addDepartureForm.setFieldsValue({
          transpotation_mode: singleDepartureDetail.transpotation_mode,
          departure_search: singleDepartureDetail.departure_search,
          departure_point: singleDepartureDetail.departure_point,
          departure_point_english:
            singleDepartureDetail.departure_point_english,
          exit_landmark_english: singleDepartureDetail.exit_landmark_english,
          exit_landmark_local: singleDepartureDetail.exit_landmark_local,
          address: singleDepartureDetail.address,
          departure_point_postal_code:
            singleDepartureDetail.departure_point_postal_code,
            route_information: singleDepartureDetail.route_information,
          });
        }
        if (location.pathname.includes("adddepaturepoint")) {
        setTimeout(()=>{
          setLoading(false)
        },1000)
        setShowDepartureDirection(false);
      }
      if (location.pathname.includes("view")) {
        setTimeout(()=>{
          setLoading(false)
        },1000)
        setShowDepartureDirection(true);
      }
    }
    return ()=>{
      setOriginLatLng({ lat: 0, lng: 0 });
    }
  }, [singleDepartureDetail.departure_lat, singleDepartureDetail.departure_lng, singleDepartureDetail?.path_lat_long_array]);

  useEffect(()=>{
    if (des_id) {
      dispatch(getdestination(des_id));
    }
  },[des_id]);

  useEffect(()=>{
    if (dep_id) {
      dispatch(getdeparture(id));
    }
  },[dep_id]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getdeparture(id));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (addDepartureSuccess === true) {
      dispatch(getdeparture(id));
      // navigate(`/viewroutedetails/${destinationId}`);
    }
  }, [addDepartureSuccess]); // eslint-disable-line

  const onAddPinClick = () => {
    const id = localStorage.getItem("departure_id");
    navigate(`/addpin/${id}`);
  };
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place, inputRef, autocompleteRef) => {
      setPathLtLg([]);
      setOriginLatLng({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      let postalAddresArray = place.address_components.filter((addressComp) =>
        addressComp.types.includes("postal_code")
      );
      if (postalAddresArray.length > 0) {
        let postalCode = postalAddresArray[0]?.long_name;
        addDepartureForm.setFieldsValue({
          departure_point_postal_code: postalCode,
        });
      }
      setShowDepartureDirection(true)
      addDepartureForm.setFieldsValue({
        departure_search: place.formatted_address,
        address: place.formatted_address,
      });
      // console.log(addDepartureForm.getFieldsValue(true),destinationLatLng,singleDestinationDetail);
    },
    options: {
      types: [],
    },
  });

  const onModeChange = () => {
    setPathLtLg([]);
    setShowDepartureDirection(false)
    addDepartureForm.setFieldsValue({
      departure_search: '',
      departure_point_postal_code: '',
      address: '',
      route_information: '',
    })
  }

  const onFinish = (value) => {
    let payload = {
      destination_id: des_id,
      transpotation_mode: value.transpotation_mode,
      departure_point: value.departure_point,
      departure_point_english: value.departure_point_english,
      departure_point_postal_code: value.departure_point_postal_code,
      route_information: value.route_information,
      address: value.address,
      departure_search: value.departure_search,
      exit_landmark_english: value?.exit_landmark_english || '',
      exit_landmark_local: value?.exit_landmark_local || '',
      departure_lat: originLatLng.lat,
      departure_lng: originLatLng.lng,
      path_lat_long_array: pathLtLg,
    };
    if (location.pathname.includes("edit")) {
      let data = { id: id, payload: payload };
      dispatch(editdeparture(data));
      navigate(-1);
    } else {
      dispatch(adddeparture(payload));
      navigate(-1);
    }
  };

  const routeChangeDetails = (data) => {
    // console.log('inside routeChangeDetails --------',data);
    // console.log('-------',data?.routes[0]?.legs[0]?.duration.text);
    addDepartureForm.setFieldsValue({
      route_information: `${data?.routes[0]?.legs[0]?.distance.text} / ${data?.routes[0]?.legs[0]?.duration.text}`,
    });
  };

  const PathChangeDetails = (data) => {
    // console.log('inside PathChangeDetails --------',data?.pathLtLg);

    setPathLtLg(data?.pathLtLg);
  };

  return (
    <AdminLayoutStyleWrapper1>
      <Card>
        <div className="left-wrapper">
          <RouteTitleContainer
            routeName={singleDestinationDetail.route_name}
          ></RouteTitleContainer>
          <div className="content-container">
            <AddDepaturePointStyleWrapper>
              <Form form={addDepartureForm} onFinish={onFinish}>
                <div>
                  <div className="departure-form">
                    <div>
                      <div className="add-destination-form-container">
                        <div>
                          <p>{TITLE_TRANSPORTATION}</p>
                          {location.pathname.includes("view") ? (
                            <p>{singleDepartureDetail?.transpotation_mode}</p>
                          ) : (
                            <Form.Item
                              name="transpotation_mode"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation
                                      .transportationRequired,
                                },
                              ]}
                              colon={false}
                              initialValue="WALKING"
                            >
                              <Select placeholder={PLACEHOLDER_TRANSPORTATION}
                                onChange={()=>onModeChange()}
                              >
                                <Option value="WALKING">Walking</Option>
                                {/* <Option value="DRIVING">Driving</Option>
                                <Option value="BICYCLING">Bicycling</Option> */}
                              </Select>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_SEARCH_DEPARTURE}</p>
                          {location.pathname.includes("view") ? (
                            <p>{singleDepartureDetail?.departure_search}</p>
                          ) : (
                            <Form.Item
                              name="departure_search"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation
                                      .departureSearchRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input
                                autoComplete="on"
                                placeholder={PLACEHOLDER_SEARCH_DEPARTURE}
                                ref={(c) => {
                                  antInputRef.current = c;
                                  if (c) ref.current = c.input;
                                }}
                              ></Input>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_ZIPCODE}</p>
                          {location.pathname.includes("view") ? (
                            <p>
                              {
                                singleDepartureDetail?.departure_point_postal_code
                              }
                            </p>
                          ) : (
                            <Form.Item
                              name="departure_point_postal_code"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation
                                      .postalCodeRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input placeholder={PLACEHOLDER_ZIPCODE}></Input>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_ADDRESS}</p>
                          {location.pathname.includes("view") ? (
                            <p>{singleDepartureDetail?.address}</p>
                          ) : (
                            <Form.Item
                              name="address"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation.addressRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input placeholder={PLACEHOLDER_ADDRESS}></Input>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_ROUTE_INFO}</p>
                          {location.pathname.includes("view") ? (
                            <p>{singleDepartureDetail?.route_information}</p>
                          ) : (
                            <Form.Item
                              name="route_information"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation
                                      .routeInfoRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input
                                placeholder={PLACEHOLDER_ROUTE_INFO}
                              ></Input>
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="add-destination-form-container">
                        <div>
                          <p>{TITLE_DEPARTURE_POINT_NAME}</p>
                          {location.pathname.includes("view") ? (
                            <p>{singleDepartureDetail?.departure_point}</p>
                          ) : (
                            <Form.Item
                              name="departure_point"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation
                                      .departurePointRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input
                                placeholder={PLACEHOLDER_DEPARTURE_POINT_NAME}
                              ></Input>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_DEPARTURE_POINT_NAME_EN}</p>
                          {location.pathname.includes("view") ? (
                            <p>
                              {singleDepartureDetail?.departure_point_english}
                            </p>
                          ) : (
                            <Form.Item
                              name="departure_point_english"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    AppConstant.FormValidation
                                      .departurePointRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input
                                placeholder={
                                  PLACEHOLDER_DEPARTURE_POINT_NAME_EN
                                }
                              ></Input>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_EXIT}</p>
                          {location.pathname.includes("view") ? (
                            <p>{singleDepartureDetail?.exit_landmark_local}</p>
                          ) : (
                            <Form.Item
                              name="exit_landmark_local"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    AppConstant.FormValidation
                                      .exitLandmarkRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input placeholder={PLACEHOLDER_EXIT}></Input>
                            </Form.Item>
                          )}
                        </div>
                        <div>
                          <p>{TITLE_EXIT_EN}</p>

                          {location.pathname.includes("view") ? (
                            <p>
                              {singleDepartureDetail?.exit_landmark_english}
                            </p>
                          ) : (
                            <Form.Item
                              name="exit_landmark_english"
                              rules={[
                                {
                                  required: false,
                                  message:
                                    AppConstant.FormValidation
                                      .exitLandmarkRequired,
                                },
                              ]}
                              colon={false}
                            >
                              <Input placeholder={PLACEHOLDER_EXIT_EN}></Input>
                            </Form.Item>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {location.pathname.includes("view") ? (
                    ""
                  ) : (
                    <div className="add-destination-save">
                      <Form.Item style={{ margin: 0 }}>
                        <Button htmlType="submit">{BUTTON_SAVE}</Button>
                      </Form.Item>
                    </div>
                  )}
                </div>
              </Form>
              {location.pathname.includes("view") && (
                <Button onClick={onAddPinClick}>{BUTTON_ADD_PIN}</Button>
              )}
            </AddDepaturePointStyleWrapper>
          </div>
        </div>
        <div className="right-wrapper">
        {loading ? (
                          <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#000"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div> ) :
          <MapContainer
            marker={{
              lat: parseFloat(destinationLatLng?.lat),
              lng: parseFloat(destinationLatLng?.lng),
            }}
            allPins={addPinDetails}
            destination={
              destinationLatLng?.lat
                ? `${parseFloat(destinationLatLng.lat)},${parseFloat(
                    destinationLatLng.lng
                  )}`
                : "0,0"
            }
            origin={
              originLatLng?.lat
                ? `${originLatLng.lat},${originLatLng.lng}`
                : "0,0"
            }
            showDirections={showDepartureDirection}
            isDeparture={true}
            isAddPin={
              location.pathname.includes("adddepaturepoint") ? false : true
            }
            zoomLevel={parseInt(singleDepartureDetail?.zoomlevel) || 12}
            onRouteInfoChange={(e) => routeChangeDetails(e)}
            PathChangeDetails={(e) => PathChangeDetails(e)}
            isView={location.pathname.includes("view") ? true : false}
            pathLtLg={
              location.pathname.includes("adddepaturepoint") ||
              location.pathname.includes("view")
                ? singleDepartureDetail?.path_lat_long_array
                : pathLtLg
            }
            travellingMode={
              location.pathname.includes("adddepaturepoint") ||
              location.pathname.includes("view")
                ? singleDepartureDetail?.transpotation_mode
                  ? singleDepartureDetail?.transpotation_mode
                  : "WALKING"
                : addDepartureForm.getFieldValue("transpotation_mode")
            }
          ></MapContainer>}
        </div>
      </Card>
      <FooterButton></FooterButton>
    </AdminLayoutStyleWrapper1>
  );
}
