import styled, { createGlobalStyle } from 'styled-components';
import { variables } from './variables';
import WithDirection from '../../library/helpers/rtl';
import '../icons.css';

import 'antd/dist/antd.min.css';

export const WrongContainer = styled.div`
  height: 100vh;
  position: relative;
  text-align: center;
  .wrong-wrapper-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      max-width: 300px;
      @media screen and (max-width: 1440px) {
        max-width: 280px;
      }
      @media screen and (max-width: 1199px) {
        max-width: 250px;
      }
    }
    h1 {
      margin: 20px 0px 10px;
      font-family: 'Inter-Bold', sans-serif;
      font-size: 26px;
      line-height: 31px;
      color: #035592;
      @media screen and (max-width: 1800px) {
        font-size: 24px;
        line-height: 30px;
      }
      @media screen and (max-width: 1600px) {
        font-size: 22px;
        line-height: 28px;
      }
      @media screen and (max-width: 1440px) {
        margin: 15px 0px 5px;
      }
      @media screen and (max-width: 1199px) {
        margin: 10px 0px 5px;
      }
    }
    p {
      font-size: 16px;
      line-height: 19px;
      color: #2d2c2c;
      @media screen and (max-width: 1600px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

export const AdminLayoutStyleWrapper = styled.div`
  display: flex;
  margin: 0px 2vw;
  flex-direction: column;
  .ant-select {
    width: 100%;
  }
  .ant-table-body{
    overflow-y: auto !important;
  }
  .img-pin {
    width: 100%;
    max-width: 100% !important;
    height: auto !important;
    max-height: 400px !important;
    object-fit: cover;
  }
  .img-pin-new {
    width: 100%;
    max-width: 60px !important;
    height: auto !important;
    max-height: 30px !important;
    object-fit: cover;
    cursor: pointer;
  }
  .add-pin-list {
    min-height: 28vh !important;
    max-height: 43vh !important;
    overflow-y: scroll;
  }
  .add-pin-list-new {
    min-height: 37vh !important;
    max-height: 43vh !important;
    overflow-y: scroll;
  }
  // .fLMVQm .ant-card {
  //   box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  //   margin: 3vh 0px;
  //   padding: 3vh;
  //   height: calc(100vh - 12vh);
  // }
  // .fLMVQm .ant-card .ant-card-body {
  //   display: flex;
  //   padding: 0px;
  //   height: 100%;
  // }
  
  .ant-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 3vh 0;
    padding: 3vh;
    // height: calc(100vh - 12vh);
    .ant-card-body {
      display: flex;
      padding: 0px;
      height: 100%;
      .left-wrapper {
        display: flex;
        flex-direction: column;
        flex-container: column;
        justify-content: space-between;
        width: 38%;
        margin-right: 2%;
        // min-height: 100vh;
        .content-container {
          // display: flex;
          // flex-flow: column;
          flex-container: column;
          justify-content: space-between;
          height: 100%;
          margin-top: 30px;
          border: 1px solid ${variables.sub_color_light_grey};
        }
      }
      .right-wrapper {
        display: flex;
        width: 60%;
        justify-content: center;
      }
    }
  }
  @media(max-width:1024px){
    .ant-card {height: auto;}
    .ant-card-body {flex-wrap: wrap;}
    .ant-card-body .left-wrapper {width: 100% !important;}
    .ant-card-body .right-wrapper {width: 100% !important;}
  }

  .ant-card-rohan {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 3vh 0;
    padding: 3vh;
    height: calc(100vh - 12vh);
    .ant-card-body {
      display: flex;
      padding: 0px;
      height: 100%;
      .left-wrapper {
        display: flex;
        flex-direction: column;
        flex-container: column;
        justify-content: space-between;
        width: 38%;
        margin-right: 2%;
        // min-height: 100vh;
        .content-container {
          // display: flex;
          // flex-flow: column;
          flex-container: column;
          justify-content: space-between;
          height: 100%;
          margin-top: 30px;
          border: 1px solid ${variables.sub_color_light_grey};
        }
      }
      .right-wrapper {
        display: flex;
        width: 60%;
        justify-content: center;
      }
    }
  }
  @media(max-width:1024px){
    .ant-card-rohan {height: auto;}
    .ant-card-body {flex-wrap: wrap;}
    .ant-card-body .left-wrapper {width: 100% !important;}
    .ant-card-body .right-wrapper {width: 100% !important;}
  }
  
  .footer-button-wrapper {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 30px;
    .ant-btn {
      min-width: 150px;
      margin-top: 0px;
    }
    .deleteRouteButton {
      height: auto;
      .subButtonText {
        display: block;
      }
    }
  }
`;

export const AdminLayoutStyleWrapper1 = styled.div`
  display: flex;
  margin: 0px 2vw;
  flex-direction: column;
  .ant-select {
    width: 100%;
  }
  .ant-table-body{
    overflow-y: auto !important;
  }
  .img-pin {
    width: 100%;
    max-width: 100% !important;
    height: auto !important;
    max-height: 400px !important;
    object-fit: cover;
  }
  .img-pin-new {
    width: 100%;
    max-width: 60px !important;
    height: auto !important;
    max-height: 30px !important;
    object-fit: cover;
    cursor: pointer;
  }
  .add-pin-list {
    min-height: 28vh !important;
    max-height: 43vh !important;
    overflow-y: scroll;
  }
  .add-pin-list-new {
    min-height: 37vh !important;
    max-height: 43vh !important;
    overflow-y: scroll;
  }
  // .fLMVQm .ant-card {
  //   box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  //   margin: 3vh 0px;
  //   padding: 3vh;
  //   height: calc(100vh - 12vh);
  // }
  // .fLMVQm .ant-card .ant-card-body {
  //   display: flex;
  //   padding: 0px;
  //   height: 100%;
  // }
  
  .ant-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 3vh 0;
    padding: 3vh;
    height: calc(100vh - 12vh);
    .ant-card-body {
      display: flex;
      padding: 0px;
      height: 100%;
      .left-wrapper {
        display: flex;
        flex-direction: column;
        flex-container: column;
        justify-content: space-between;
        width: 38%;
        margin-right: 2%;
        // min-height: 100vh;
        .content-container {
          // display: flex;
          // flex-flow: column;
          flex-container: column;
          justify-content: space-between;
          height: 100%;
          margin-top: 30px;
          border: 1px solid ${variables.sub_color_light_grey};
        }
      }
      .right-wrapper {
        display: flex;
        width: 60%;
        justify-content: center;
      }
    }
  }
  @media(max-width:1024px){
    .ant-card {height: auto;}
    .ant-card-body {flex-wrap: wrap;}
    .ant-card-body .left-wrapper {width: 100% !important;}
    .ant-card-body .right-wrapper {width: 100% !important;}
  }
  
  .footer-button-wrapper {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 30px;
    .ant-btn {
      min-width: 150px;
      margin-top: 0px;
    }
    .deleteRouteButton {
      height: auto;
      .subButtonText {
        display: block;
      }
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  body{
    font-family: RobotoLight, sans-serif;
    color: ${variables.sub_color_dark_gray};
    font-size: 16px;
    @media screen and (max-width: 1600px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1199px) {
      font-size: 13px
    }
    h1{
      font-size: 40px;
    }
    .ant-btn {
      margin: 14px 0px;
      height: 50px;
      color: ${variables.sub_color_dark_gray};
      border: 1px solid ${variables.sub_color_dark_gray};
      background-color: ${variables.sub_color_white};
      &:focus {
        color: ${variables.sub_color_dark_gray};
        border: 1px solid ${variables.sub_color_dark_gray};
        background-color: ${variables.sub_color_white};
      }
      &:hover {
        background-color: ${variables.sub_color_dark_gray};
        border: 1px solid ${variables.sub_color_dark_gray};
        color: ${variables.sub_color_white};
      }
      font-size: 16px;
      @media screen and (max-width: 1600px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 13px
      }
    }
    .ant-select-selector{
      border-color: ${variables.sub_color_light_grey} !important;
      &:hover {
        border-color: ${variables.sub_color_dark_gray} !important;
        /* box-shadow: 0 0 0 2px 0.2 ${variables.sub_color_dark_gray} !important; */
      }
      &:focus {
        border-color: ${variables.sub_color_dark_gray} !important;
        /* box-shadow: 0 0 0 2px rgba((${variables.sub_color_dark_gray}), 0.2)!important; */
      }
    }
    .ant-input {
      border-color: ${variables.sub_color_light_grey};
      &:hover {
        border-color: ${variables.sub_color_dark_gray};
        box-shadow: 0 0 0 2px 0.2 ${variables.sub_color_dark_gray};
      }
      &:focus {
        border-color: ${variables.sub_color_dark_gray};
        box-shadow: 0 0 0 2px rgba((${variables.sub_color_dark_gray}), 0.2);
      }
    }
    .ant-modal {
      .ant-modal-body {
        justify-content: center;
        h2 {
          text-align: center;
        }
      }
      .ant-modal-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .ant-btn {
          min-width: 25%;
        }
      } 
    }
    .addPinModal {
      .ant-modal-body {
        padding: 50px 50px 0px 50px;
        .ant-row {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          .ant-form-item-label {
            max-width: 30%;
          }
          .ant-form-item-control {
            max-width: 40%;
          }
        }
      }
    }
  }
`;

export default WithDirection(GlobalStyles);
