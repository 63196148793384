// button
export const BUTTON_LOGIN = 'Login / ログイン'
export const BUTTON_LOGOUT = 'Logout / ログアウト'
export const BUTTON_BACK = 'Return / 戻る'
export const BUTTON_ADD_ROUTE = 'Add Route / ルートを追加'
export const BUTTON_DELETE_ROUTE = 'Delete Route / ルートを削除'
export const BUTTON_EXIT = 'Quit / 破棄'
export const BUTTON_SAVE_ROUTE = 'Select Route / ルートを選択'
export const BUTTON_SAVE = 'Save / 保存'
export const BUTTON_ADD_PIN = 'Add Pin / ピンの追加'
export const BUTTON_PHOTO_DIRECTION = 'Photo Direction / 写真の方向'
export const BUTTON_D_PHOTO = 'D Photo'
export const BUTTON_MAP_SIZE = 'Zoom Level / 地図縮尺'
export const BUTTON_DETAIL = 'Edit PIN / PIN編集'
export const BUTTON_EDIT = 'Edit Route Info / ルート情報編集'
export const BUTTON_ADD_DEPATURE = 'Add Departure Point / 出発地を追加'
export const BUTTON_EDIT_DESTINATION = 'Edit Destination Information / 目的地情報の編集'
// title
export const TITLE_ROUTE_NAME = 'Route Name / ルート名'
export const TITLE_TRANSPORTATION = 'Transportation / 移動手段'
export const TITLE_SEARCH_DEPARTURE = 'Search Departure Point / 出発地を検索する'
export const TITLE_ZIPCODE = 'Postal Code / 郵便番号'
export const TITLE_ADDRESS = 'Address / 住所'
export const TITLE_ROUTE_INFO = 'Route information / ルート情報'
export const TITLE_DEPARTURE_POINT_NAME = 'Departure Point Name(Local) / 出発地名'
export const TITLE_DEPARTURE_POINT_NAME_EN = 'Departure Point Name(English) / 出発地名(英語)'
export const TITLE_EXIT = 'Exit, Landmark(Local) / 出口、目標'
export const TITLE_EXIT_EN = 'Exit, Landmark(English) / 出口、目標(英語)'
export const TITLE_DESTINATION_SEARCH = 'Search Destination / 目的地を検索する'
export const TITLE_DESTINATION_NAME = 'Destination Name(Local) / 目的地名'
export const TITLE_DESTINATION_NAME_EN = 'Destination Name(English) / 目的地名(英語)'
export const TITLE_TOTAL_DESTINATIONS = 'Number of destination / 目的地数 : '
export const TITLE_TOTAL_DEPARTURE = 'Number of departure /出発回数 : '
export const TITLE_TOTAL_ROUTES = 'Number of route / ルート数 : '
export const TITLE_ROUTE_LIST = 'Route List / ルート一覧'
export const TITLE_LIST_OF_ROUTE = 'Route List / ルート一覧'
// placeholder
export const PLACEHOLDER_ROUTE_NAME = ''
export const PLACEHOLDER_TRANSPORTATION = '移動手段'
export const PLACEHOLDER_SEARCH_DEPARTURE = '出発地検索'
export const PLACEHOLDER_ZIPCODE = '郵便番号'
export const PLACEHOLDER_ADDRESS = '住所'
export const PLACEHOLDER_ROUTE_INFO = 'ルート情報'
export const PLACEHOLDER_DEPARTURE_POINT_NAME = '出発地名'
export const PLACEHOLDER_DEPARTURE_POINT_NAME_EN = '出発地名(英語)'
export const PLACEHOLDER_EXIT = '出口、目標'
export const PLACEHOLDER_EXIT_EN = '出口、目標(英語)'
export const PLACEHOLDER_DESTINATION_SEARCH = '目的地検索'
export const PLACEHOLDER_DESTINATION_NAME = '目的地名'
export const PLACEHOLDER_DESTINATION_NAME_EN = '目的地名(英語)'
// list columns
export const COLUMN_ROUTE_LIST_1 = 'Number / 番号'
export const COLUMN_ROUTE_LIST_2 = 'Route Name / ルート名'
export const COLUMN_ROUTE_LIST_3 = 'Destination Name(Local) / 目的地名'
export const COLUMN_ROUTE_LIST_4 = 'Destination Name(English) / 目的地名(英語)'
export const COLUMN_ROUTE_LIST_5 = 'xxxxxx'
export const COLUMN_ROUTE_LIST_6 = 'Departure Count / 出発カウント'
export const COLUMN_ROUTE_LIST_BUTTON = 'Details / 詳細'
export const COLUMN_ROUTE_DETAIL_1 = 'Departing Point / 出発地'
export const COLUMN_ROUTE_DETAIL_2 = 'Transportation / 交通手段'
export const COLUMN_ROUTE_DETAIL_3 = 'Exit point / 出口点'

// label
export const LABEL_EMAIL = 'Email / メールアドレス'
export const LABEL_PASSWORD = 'Password / パスワード'
// MODAL
export const DESTINATION_DELETE_MODAL_TEXT =
  '選択したルートに対するすべての出発は自動的に削除されます？\nAre you sure? All departures against selected routes will be deleted automatically.'
export const SINGLE_DESTINATION_DELETE_MODAL_TEXT =
  'このルートのすべての出発は自動的に削除されます？\nAre you sure? All departures against this route will be deleted automatically.'
export const RETURN_MODAL_TEXT = '本当にこのページを離れますか？ 入力した情報が破棄されます。\nAre you sure? Youre changes will be discarded.'
export const MODAL_CANCEL_BUTTON = 'Cancel / キャンセル'
export const MODAL_DELETE_BUTTON = 'Delete / 削除'
export const MODAL_RETURN_BUTTON = 'Return / 戻る'
export const DELETE_DEPARTURE_POINT = 'Delete departure point'
export const DEPARTURE_DELETE_MODAL_TEXT = 'Are you sure? Selected departure points will be deleted.'
export const DELETE_PIN_CONFIRMATION = 'Are you sure want to delete pin? / このピンを削除してもよろしいですか?'
