import actions from "./actions";

const initState = {
  idToken: localStorage.getItem("id_token"),
  loginError: "",
  globalLoader: 0,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        loginError: "",
        idToken: "",
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loginError: "",
        idToken: action.idToken,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loginError: action.loginError,
      };
    case actions.LOGOUT:
      return {
        ...state,
        idToken: "",
      };
    default:
      return state;
  }
}
