import React, { useEffect, useState } from "react";
import { Card, Table, Button, Modal, notification } from "antd";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import AdminRouteListStyleWrapper from "./AdminRouteList.styles";
import { useNavigate } from "react-router-dom";
import actions from "../../redux/admin/actions";
import { useDispatch, useSelector } from "react-redux";
import { AdminLayoutStyleWrapper } from "../../assets/styles/globalStyle";
import {
  BUTTON_ADD_ROUTE,
  BUTTON_DELETE_ROUTE,
  COLUMN_ROUTE_LIST_1,
  COLUMN_ROUTE_LIST_2,
  COLUMN_ROUTE_LIST_3,
  COLUMN_ROUTE_LIST_4,
  COLUMN_ROUTE_LIST_5,
  COLUMN_ROUTE_LIST_BUTTON,
  TITLE_TOTAL_DESTINATIONS,
  TITLE_TOTAL_ROUTES,
  TITLE_ROUTE_LIST,
  DESTINATION_DELETE_MODAL_TEXT,
  MODAL_CANCEL_BUTTON,
  MODAL_DELETE_BUTTON,
  TITLE_TOTAL_DEPARTURE,
  COLUMN_ROUTE_LIST_6,
} from "../../config/constant/language";
import { ThreeDots } from "react-loader-spinner";
import CopyToClipboard from "react-copy-to-clipboard";

export default function AdminRouteList() {
  const dispatch = useDispatch();
  let allDestinationDetails = useSelector(
    (state) => state.Admin.allDestinationDetails
  );
  let loading = useSelector((state) => state.Admin.loading);

  let allDepartureCount = useSelector((state) => state.Admin.allDepartureCount);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [currentDestination, setCurrentDestination] = useState("");
  function onClick(key) {
    setCurrentDestination(key);
    setTimeout(() => {
      setCurrentDestination("");
    }, 1000);
  }
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(!loading){
  //     setIsLoading(true);
  //     setInterval(() => {
  //       setIsLoading(false);
  //     },500)
  //   }
  // }, [loading])
  

  const columns = [
    {
      title: COLUMN_ROUTE_LIST_1,
      dataIndex: "number",
    },
    {
      title: COLUMN_ROUTE_LIST_2,
      dataIndex: "routeName",
    },
    {
      title: COLUMN_ROUTE_LIST_3,
      dataIndex: "destinationLocalName",
    },
    {
      title: COLUMN_ROUTE_LIST_4,
      dataIndex: "destinationEnglishName",
    },
    {
      title: COLUMN_ROUTE_LIST_6,
      dataIndex: "departureCount",
    },
    {
      title: COLUMN_ROUTE_LIST_BUTTON,
      dataIndex: "details",
      render: (text, record) => (
        <Button onClick={() => navigate(`/viewroutedetails/${record.key}`)}>
          Details / 詳細
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "copy",
      render: (text, record) => (
        record.departureCount > 0 && <CopyToClipboard
          options={{ message: "copied!" }}
          text={`https://app.howcanigetthere.com/${record.key}`}
        >
          <Button onClick={() => onClick(record.key)} id={record.key}>
            {currentDestination === record.key ? "Copied" : "Copy Link"}
          </Button>
        </CopyToClipboard>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRoutes(selectedRowKeys);
    },
  };

  useEffect(() => {
    dispatch(actions.getalldestination());
    dispatch(actions.getalldeparturecount());
  }, []);

  const onDeleteClick = () => {
    if (selectedRoutes.length > 0) {
      setDeleteModal(true);
    } else {
      notification.error({ message: "Please select rooute first" });
    }
  };
  // console.log({allDestinationDetails});

  const onModalDeleteClick = () => {
    // console.log({ selectedRoutes });
    dispatch(actions.deletedestination(selectedRoutes));
    setSelectedRoutes([]);
    setDeleteModal(false);
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "calc(100vh/2)",
          }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#000"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <AdminLayoutStyleWrapper>
          <AdminHeader />
          <AdminRouteListStyleWrapper>
            <div className="number-of-dest-and-route-container">
              <div>
                {TITLE_TOTAL_DESTINATIONS}
                {allDestinationDetails?.length}
              </div>
              <div>
                {TITLE_TOTAL_DEPARTURE}
                {allDepartureCount}
              </div>
            </div>
            <Card>
              <div className="table-container">
                <div className="table-heading-text">{TITLE_ROUTE_LIST}</div>
                {isLoading ? 
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#000"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{display: 'flex', justifyContent: 'center'}}
                    wrapperClassName=""
                    visible={true}
                  />
                 : <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={allDestinationDetails}
                  pagination={false}
                  scroll={{
                    y: "45vh",
                  }}
                />}
              </div>
              <div className="route-add-delete-button-container">
                <Button
                  onClick={() => {
                    navigate("/adddestinationpoint");
                  }}
                >
                  {BUTTON_ADD_ROUTE}
                </Button>
                <Button onClick={onDeleteClick}>{BUTTON_DELETE_ROUTE}</Button>
              </div>
            </Card>
            <Modal
              centered
              closable={false}
              visible={deleteModal}
              onOk={onModalDeleteClick}
              onCancel={() => setDeleteModal(false)}
              okText={MODAL_DELETE_BUTTON}
              cancelText={MODAL_CANCEL_BUTTON}
            >
              <h2>{DESTINATION_DELETE_MODAL_TEXT}</h2>
            </Modal>
          </AdminRouteListStyleWrapper>
        </AdminLayoutStyleWrapper>
      )}
    </>
  );
}
