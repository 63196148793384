import styled from 'styled-components';
import WithDirection from '../../library/helpers/rtl';

const LoginStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  margin: 0px auto;
  width: 500px;
  @media screen and (max-width: 1024px) {
    width: 450px;
  }
  @media screen and (max-width: 786px) {
    width: 350px;
  }
  @media screen and (max-width: 425px) {
    width: 300px;
  }
  @media screen and (max-width: 320px) {
    width: 280px;
  }
  h1 {
    margin-bottom: 50px;
  }
  .ant-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .ant-form-item {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      .ant-row {
        display: block;
        .ant-form-item-label {
          display: flex;
        }
        .ant-input {
          height: 50px;
        }
      }
    }
    .ant-btn {
      width: 60%;
      align-self: center;
      margin-top: 50px;
    }
  }
`;
export default WithDirection(LoginStyleWrapper);
