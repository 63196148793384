import styled from 'styled-components';
import WithDirection from '../../library/helpers/rtl';

const AddDestinationPointStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0px 20px;
  .add-destination-form-container {
    margin-top: 20px;
    > div {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      p {
        min-width: 50%;
        margin: 0px 10px 0px 0px;
        align-self: flex-start;
      }
      .ant-form-item {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 0px;
        .ant-row {
          width: 100%;
          margin-bottom: 0px;
          .ant-col {
            margin-bottom: 0px;
            display: flex;
            flex-direction: column;
            &:nth-child(2) {
              margin-bottom: 0px;
            }
            .ant-form-item-control-input {
              margin-bottom: 0px;
              width: 100%;
              div{
                margin-bottom: 0px;
              }
              .ant-form-item-control-input-content {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
  .add-destination-save {
    display: flex;
    min-width: 150px;
    margin: 0px auto;
    .ant-btn {
      width: auto;
      margin: 20px auto;
    }
  }
`;
export default WithDirection(AddDestinationPointStyleWrapper);
