import React, { useEffect, useState, useRef } from 'react'
import { Button, Card, Table, Select, Modal, Form, Input, notification, Col, Row } from 'antd'
import { AdminLayoutStyleWrapper1 } from '../../assets/styles/globalStyle'
import MapContainer from '../../components/MapContainer/MapContainer'
import RouteTitleContainer from '../../components/RouteTitleContainer/RouteTitleContainer'
import AddNewRouteStyleWrapper from './AddPin.styles'
import AddPinStyleWrapper from './AddPin.styles'
import FooterButton from '../../components/FooterButton/FooterButton'
import { BUTTON_ADD_PIN, BUTTON_SAVE, MODAL_DELETE_BUTTON, MODAL_CANCEL_BUTTON, DELETE_PIN_CONFIRMATION } from '../../config/constant/language'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../redux/admin/actions'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { ThreeDots } from 'react-loader-spinner'

export default function AddPin() {
  //  const navigate = useNavigate();
  const pinRef = useRef(null)
  const currPinRef = useRef(null)

  const { id } = useParams()
  const { Option } = Select
  const dispatch = useDispatch()
  const [selectedPin, setSelectedPin] = useState([])
  const navigate = useNavigate()
  const { getallpin, editpin, deletepin, addpin, getpin, getdestination, cleardata, getdeparture, editdeparture } = actions
  const [originLatLng, setOriginLatLng] = useState({ lat: 0, lng: 0 })
  const [destinationLatLng, setDestinationLatLng] = useState({
    lat: 0,
    lng: 0,
  })
  const [openModal, setOpenModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageUpload, setImageUpload] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [addPinForm, setAddPinForm] = Form.useForm()
  const [midPoint, setMidpoint] = useState([])
  const [selectMarker, setSelectMarker] = useState(null)
  const [allPin, setAllPin] = useState([])
  const fixedMarkers = useRef([{ lat: 0, lng: 0 }])
  const [departureId, setDepartureId] = useState('')
  const [destinationId, setDestinationId] = useState('')
  const [currentPinId, setCurrentPinId] = useState(0)

  let singlePinDetail = useSelector((state) => state.Admin.singlePinDetail)
  let loading = useSelector((state) => state.Admin.loading)
  let addPinDetails = useSelector((state) => state.Admin.addPinsDetails)
  let { editPinSuccess, addPinSuccess, deletePinSuccess } = useSelector((state) => state.Admin)
  let singleDestinationDetail = useSelector((state) => state.Admin.singleDestinationDetail)
  let singleDepartureDetail = useSelector((state) => state.Admin.singleDepartureDetail)
  const [zoomLevel, setZoomLevel] = useState(`${singleDepartureDetail?.zoomlevel || '12'}`)

  const [pathLtLg, setPathLtLg] = useState([])
  let location = useLocation()
  const zoomValue = [
    5, 6, 7, 8, 9, 10, 11, 12,
    //, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25
  ]

  useEffect(() => {
    if (selectedPin[0] === 'edit') {
      setImageUpload(singlePinDetail?.image)
      addPinForm.setFieldsValue({
        text2: singlePinDetail?.orientation,
      })
    }
  }, [singlePinDetail])
  useEffect(() => {
    if (id !== undefined) {
      dispatch(getdeparture(id))
      dispatch(getallpin(id))
    }
    const destId = localStorage.getItem('destination_id')
    setDestinationId(destId)
    if (destId) {
      dispatch(getdestination(destId))
    } else {
      navigate('/adddestinationpoint')
    }
    const timeout = setTimeout(() => {
      dispatch(cleardata())
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (addPinDetails) {
      // console.log({addPinDetails});
      setAllPin(addPinDetails)
    }
    currPinRef?.current?.scrollIntoView()
  }, [addPinDetails])

  useEffect(() => {
    setOpenModal(false)

    if (editPinSuccess) {
      setSelectedPin([])
    }
    if (addPinSuccess || deletePinSuccess) {
      // console.log({addPinSuccess});
      // dispatch(actions.getallpin(id));
      setOpenModal(false)
    }
    setTimeout(() => {
      dispatch(getallpin(id))
    }, 500)
    // console.log({currPinRef,currentPinId});
  }, [addPinSuccess, editPinSuccess, deletePinSuccess])

  useEffect(() => {
    if (singleDestinationDetail) {
      setDestinationLatLng({
        lat: parseFloat(singleDestinationDetail?.destination_lat),
        lng: parseFloat(singleDestinationDetail?.destination_lng),
      })
    }
  }, [singleDestinationDetail])

  useEffect(() => {
    if (singleDepartureDetail) {
      const zoomValue = cloneDeep(singleDepartureDetail?.zoomlevel)
      setZoomLevel(zoomValue || 12)
      setOriginLatLng({
        lat: parseFloat(singleDepartureDetail.departure_lat),
        lng: parseFloat(singleDepartureDetail.departure_lng),
      })
    }
  }, [singleDepartureDetail])

  const PathChangeDetails = (data) => {
    setPathLtLg(data?.pathLtLg)
  }

  useEffect(() => {
    const id = localStorage.getItem('departure_id')
    setDepartureId(id)
    if (id) {
      dispatch(getdeparture(id))
    }
    // console.log({addPinDetails});
    // else {
    //   navigate("/viewdepaturepoint/id");
    // }
    const timeout = setTimeout(() => {
      dispatch(cleardata())
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  const routeChangeDetails = (data) => {
    singleDepartureDetail.route_information = `${data?.routes[0]?.legs[0]?.distance.text} / ${data?.routes[0]?.legs[0]?.duration.text}`
  }

  const handleChangeMidPoint = ({ lat, lng }) => {
    setMidpoint({ lat: lat, lng: lng })
  }
  const columns = [
    {
      title: 'numbers',
      dataIndex: 'number',
      width: '93%',
      colSpan: 4,
      render: (text, record, index) => {
        return (
          <div
            style={{ display: 'flex', flexFlow: 'column' }}
            key={index}
            ref={index === currentPinId ? currPinRef : pinRef}>
            <Row style={{ alignItems: 'center' }}>
              <Col span={2}>{index + 1}</Col>
              <Col span={4}>
                <img
                  src={record.image}
                  alt='pin'
                  className='img-pin-new'
                  onClick={() => window.open(record.image)}
                />
              </Col>
              <Col
                span={7}
                style={{ display: 'flex', justifyContent: 'center' }}>
                {record.orientation}
              </Col>
              <Col span={5}>
                <Button
                  className='image-button'
                  onClick={() => (setSelectedPin(['edit', record.key, record]), setCurrentPinId(index), onModalEditClick(record.key))}>
                  Edit
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  className='image-button'
                  onClick={() => (setSelectedPin([record.key]), setDeleteModal(true))}>
                  Delete
                </Button>
              </Col>
            </Row>
            {/* <Row style={{ margin: "10px 2px" }} >
                <Col span={24}>
                  <img src={record.image} alt="pin" className="img-pin" />
                 </Col>
            </Row> */}
          </div>
        )
      },
    },
  ]
  const uploadImage = async (e) => {
    const file = e.target.files[0]

    setFileName(file?.name)

    const base64Image = await convertBase64(file)
    setImageUpload(base64Image)
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const onDeleteDeparturePointClick = () => {
    if (selectedPin.length > 0) {
      setDeleteModal(true)
    } else {
      notification.error({ message: 'Please select Pin first' })
    }
  }
  const onModalDeleteClick = () => {
    // console.log({selectedPin,addPinDetails});
    dispatch(actions.deletepin(selectedPin))
    setTimeout(() => {
      dispatch(actions.getallpin(id))
      setDeleteModal(false)
      setSelectedPin([])
    }, 500)
  }
  const onModalEditClick = (record) => {
    dispatch(getpin(record))
    setOpenModal(true)
  }

  const onFinish = (value) => {
    let payload = {
      routeinfo_id: id,
      pin: (Math.random() + 1).toString(36).substring(6),
      orientation: value.text2,
      image: imageUpload,
      imageName: fileName,
      pin_lat: midPoint.lat,
      pin_lng: midPoint.lng,
    }
    if (selectedPin[0] === 'edit') {
      currPinRef.current.scrollIntoView()
      let editPayload = {
        routeinfo_id: id,
        pin: selectedPin[2].pin,
        orientation: value.text2,
        // image: imageUpload,
        pin_lat: selectedPin[2].pin_lat,
        pin_lng: selectedPin[2].pin_lng,
      }
      if (fileName) {
        editPayload.image = imageUpload
      }
      let data = { id: selectedPin[1], payload: editPayload }
      dispatch(editpin(data))
      setOpenModal(false)
    } else {
      dispatch(addpin(payload))
      setOpenModal(false)
      setCurrentPinId(addPinDetails.length)
    }
    // dispatch(getallpin(id));
  }

  const middlePoint = (lat1, lng1, lat2, lng2) => {
    // -- Longitude difference
    if (typeof Number.prototype.toRad === 'undefined') {
      // eslint-disable-next-line no-extend-native
      Number.prototype.toRad = function () {
        return (this * Math.PI) / 180
      }
    }
    if (typeof Number.prototype.toDeg === 'undefined') {
      // eslint-disable-next-line no-extend-native
      Number.prototype.toDeg = function () {
        return this * (180 / Math.PI)
      }
    }
    let dLng = (lng2 - lng1).toRad()
    // -- Convert to radians
    lat1 = lat1.toRad()
    lat2 = lat2.toRad()
    lng1 = lng1.toRad()

    let bX = Math.cos(lat2) * Math.cos(dLng)
    let bY = Math.cos(lat2) * Math.sin(dLng)
    let lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY))
    let lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX)
    // -- Return result
    let mid = {
      lat: lat3.toDeg(),
      lng: lng3.toDeg(),
    }
    return mid
  }

  let point = middlePoint(originLatLng.lat, originLatLng.lng, destinationLatLng.lat, destinationLatLng.lng)

  const mapScale = (e) => {
    setZoomLevel(parseInt(e))
  }

  useEffect(() => {
    setMidpoint(point)
  }, [originLatLng])

  const onMarkerDragEnd = (event, index) => {
    event.preventDeafult()
    // console.log('pin dragged');

    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng()
    handleChangeMidPoint({ lat: newLat, lng: newLng })
    // console.log('pin dragged',{newLat,newLng});

    const array = fixedMarkers.current
    array[index] = event.latLng.toJSON()
    fixedMarkers.current = [...array]
  }

  const onClickMarker = (e, index) => {
    e.preventDeafult()
    setSelectMarker(index)
  }
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedPin(selectedRowKeys)
    },
  }
  const onReturnClick = () => {
    console.log('onReturnClick🎈')
    let payload = {
      zoomlevel: Number(zoomLevel),
    }
    let data = { id: id, payload: payload }
    dispatch(editdeparture(data))
    navigate(`/viewdepaturepoint/${id}`)
  }

  return (
    <>
      {/* {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "calc(100vh/2)",
            position:"absolute",
            height: "100vh",
            width: "100vw",
            zIndex: 1,
            backgroundColor: "white"
          }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#000"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (<>
      </>
      )} */}
      <AdminLayoutStyleWrapper1>
        <AddPinStyleWrapper>
          <Card>
            <div className='left-wrapper'>
              <RouteTitleContainer routeName={singleDestinationDetail.route_name}></RouteTitleContainer>

              <div
                className='content-container'
                style={{ display: 'flex' }}>
                <AddNewRouteStyleWrapper className='pin-container'>
                  <div>
                    <div className='button-wrapper'>
                      <Button onClick={() => setOpenModal(true)}>{BUTTON_ADD_PIN}</Button>
                    </div>
                    <div className='image-button add-pin-list-new'>
                      {loading ? (
                        // <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ThreeDots
                          height='80'
                          width='80'
                          radius='9'
                          color='#000'
                          ariaLabel='three-dots-loading'
                          wrapperStyle={{ display: 'flex', justifyContent: 'center' }}
                          wrapperClassName=''
                          visible={true}
                        />
                      ) : (
                        // </div>
                        <Table
                          style={{ height: 'auto', overflowX: 'hidden' }}
                          columns={columns}
                          rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                          }}
                          dataSource={allPin}
                          pagination={false}
                        />
                      )}
                    </div>
                  </div>
                  {/* <div className="zoomLevel">
                      <p>地図縮尺</p>
                      <Select
                        placeholder="地図縮尺"
                        value={zoomLevel}
                        optionFilterProp="children"
                        onChange={mapScale}
                      >
                        {zoomValue.map((value) => {
                          return <Option value={value}>{value}</Option>;
                        })}
                      </Select>
                    </div> */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={onReturnClick}>{BUTTON_SAVE}</Button>
                    <Button onClick={onDeleteDeparturePointClick}>Delete Selected Pin</Button>
                  </div>
                </AddNewRouteStyleWrapper>
              </div>
            </div>
            <div className='right-wrapper'>
              <MapContainer
                midPoint={midPoint !== undefined ? midPoint : { lat: 0, lng: 0 }}
                allPins={addPinDetails}
                // markers={Pins}
                // onClickMarker={onClickMarker}
                onDragEnd={onMarkerDragEnd}
                marker={{
                  lat: parseFloat(destinationLatLng?.lat),
                  lng: parseFloat(destinationLatLng?.lng),
                }}
                showDirections={true}
                onAddPinCall={openModal}
                zoomLevel={parseInt(zoomLevel)}
                destination={destinationLatLng?.lat ? `${parseFloat(destinationLatLng.lat)},${parseFloat(destinationLatLng.lng)}` : '0,0'}
                origin={originLatLng?.lat ? `${originLatLng.lat},${originLatLng.lng}` : '0,0'}
                isDeparture={true}
                isAddPin={true}
                onRouteInfoChange={routeChangeDetails}
                PathChangeDetails={PathChangeDetails}
                isView={true}
                pathLtLg={
                  location.pathname.includes('addpin') && singleDepartureDetail?.path_lat_long_array
                    ? singleDepartureDetail?.path_lat_long_array
                    : pathLtLg
                }
                travellingMode={
                  location.pathname.includes('addpin') && singleDepartureDetail?.transpotation_mode
                    ? singleDepartureDetail?.transpotation_mode
                    : 'WALKING'
                }></MapContainer>
            </div>
          </Card>
          <Modal
            centered
            closable={false}
            visible={deleteModal}
            onOk={onModalDeleteClick}
            onCancel={() => setDeleteModal(false)}
            okText={MODAL_DELETE_BUTTON}
            cancelText={MODAL_CANCEL_BUTTON}>
            <h2>{DELETE_PIN_CONFIRMATION}</h2>
          </Modal>
          <Modal
            centered
            closable={false}
            maskClosable={true}
            visible={openModal}
            onOk={() => {
              addPinForm.submit()
            }}
            onCancel={() => {
              setOpenModal(false)
              setFileName('')
            }}
            okText='Save'
            cancelText='Cancel'
            className='addPinModal'>
            <Form
              form={addPinForm}
              onFinish={onFinish}>
              {/* {openModal && console.log({singlePinDetail})} */}
              <div>
                <Form.Item
                  label='Photo'
                  name='Photo'
                  // initialValue={singlePinDetail?.image ? singlePinDetail?.image : '' }
                  rules={[
                    {
                      required: selectedPin[0] !== 'edit',
                      message: 'Please select Photo',
                    },
                  ]}
                  colon={false}>
                  <Input
                    type='file'
                    // value={singlePinDetail?.image || null}
                    onChange={(e) => {
                      uploadImage(e)
                    }}
                    required></Input>
                </Form.Item>
              </div>
              <Form.Item
                label='Direction'
                name='text2'
                // value= {singlePinDetail?.orientation}
                rules={[
                  {
                    required: true,
                    message: 'select one option',
                  },
                ]}
                colon={false}
                initialValue={singlePinDetail?.orientation ? singlePinDetail?.orientation : 'Straight'}>
                <Select
                  placeholder='placeholder'
                  value={singlePinDetail?.orientation ? singlePinDetail?.orientation : 'Straight'}>
                  <Option value='Straight'>Straight</Option>
                  <Option value='Left'>Left</Option>
                  <Option value='Right'>Right</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </AddPinStyleWrapper>
        <FooterButton></FooterButton>
      </AdminLayoutStyleWrapper1>
    </>
  )
}
