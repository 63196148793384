const actions = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",

  ADD_DESTINATION: "ADD_DESTINATION",
  ADD_DESTINATION_SUCCESS: "ADD_DESTINATION_SUCCESS",
  ADD_DESTINATION_FAILED: "ADD_DESTINATION_FAILED",

  GET_SINGLE_DESTINATION: "GET_SINGLE_DESTINATION",
  GET_SINGLE_DESTINATION_SUCCESS: "GET_SINGLE_DESTINATION_SUCCESS",
  GET_SINGLE_DESTINATION_FAILED: "GET_SINGLE_DESTINATION_FAILED",

  EDIT_DESTINATION: "EDIT_DESTINATION",
  EDIT_DESTINATION_SUCCESS: "EDIT_DESTINATION_SUCCESS",
  EDIT_DESTINATION_FAILED: "EDIT_DESTINATION_FAILED",

  GET_ALL_DESTINATION: "GET_ALL_DESTINATION",
  GET_ALL_DESTINATION_SUCCESS: "GET_ALL_DESTINATION_SUCCESS",
  GET_ALL_DESTINATION_FAILED: "GET_ALL_DESTINATION_SUCCESS",

  GET_ALL_DEPARTURE_COUNT: "GET_ALL_DEPARTURE_COUNT",
  GET_ALL_DEPARTURE_COUNT_SUCCESS: "GET_ALL_DEPARTURE_COUNT_SUCCESS",
  GET_ALL_DEPARTURE_COUNT_FAILED: "GET_ALL_DEPARTURE_COUNT_SUCCESS",

  DELETE_DESTINATION: "DELETE_DESTINATION",
  DELETE_DESTINATION_SUCCESS: "DELETE_DESTINATION_SUCCESS",
  DELETE_DESTINATION_FAILED: "DELETE_DESTINATION_FAILED",

  ADD_DEPARTURE: "ADD_DEPARTURE",
  ADD_DEPARTURE_SUCCESS: "ADD_DEPARTURE_SUCCESS",
  ADD_DEPARTURE_FAILED: "ADD_DEPARTURE_FAILED",

  GET_SINGLE_DEPARTURE: "GET_SINGLE_DEPARTURE",
  GET_SINGLE_DEPARTURE_SUCCESS: "GET_SINGLE_DEPARTURE_SUCCESS",
  GET_SINGLE_DEPARTURE_FAILED: "GET_SINGLE_DEPARTURE_FAILED",

  EDIT_DEPARTURE: "EDIT_DEPARTURE",
  EDIT_DEPARTURE_SUCCESS: "EDIT_DEPARTURE_SUCCESS",
  EDIT_DEPARTURE_FAILED: "EDIT_DEPARTURE_FAILED",

  GET_ALL_DEPARTURE: "GET_ALL_DEPARTURE",
  GET_ALL_DEPARTURE_SUCCESS: "GET_ALL_DEPARTURE_SUCCESS",
  GET_ALL_DEPARTURE_FAILED: "GET_ALL_DEPARTURE_SUCCESS",

  DELETE_DEPARTURE: "DELETE_DEPARTURE",
  DELETE_DEPARTURE_SUCCESS: "DELETE_DEPARTURE_SUCCESS",
  DELETE_DEPARTURE_FAILED: "DELETE_DEPARTURE_FAILED",

  ADD_PIN: "ADD_PIN",
  ADD_PIN_SUCCESS: "ADD_PIN_SUCCESS",
  ADD_PIN_FAILED: "ADD_PIN_FAILED",

  GET_ALL_PIN: "GET_ALL_PIN",
  GET_ALL_PIN_SUCCESS: "GET_ALL_PIN_SUCCESS",
  GET_ALL_PIN_FAILED: "GET_ALL_PIN_FAILED",

  GET_SINGLE_PIN: "GET_SINGLE_PIN",
  GET_SINGLE_PIN_SUCCESS: "GET_SINGLE_PIN_SUCCESS",
  GET_SINGLE_PIN_FAILED: "GET_SINGLE_PIN_FAILED",

  EDIT_PIN: "EDIT_PIN",
  EDIT_PIN_SUCCESS: "EDIT_PIN_SUCCESS",
  EDIT_PIN_FAILED: "EDIT_PIN_FAILED",

  DELETE_PIN: "DELETE_PIN",
  DELETE_PIN_SUCCESS: "DELETE_PIN_SUCCESS",
  DELETE_PIN_FAILED: "DELETE_PIN_FAILED",

  CLEAR_DEPARTURE_DATA: "CLEAR_DEPARTURE_DATA",
  CLEAR_DATA: "CLEAR_DATA",

  adddestination: (payload) => ({
    type: actions.ADD_DESTINATION,
    payload: payload,
  }),

  getdestination: (payload) => ({
    type: actions.GET_SINGLE_DESTINATION,
    payload: payload,
  }),

  editdestination: (payload) => ({
    type: actions.EDIT_DESTINATION,
    payload: payload,
  }),

  getalldestination: () => ({
    type: actions.GET_ALL_DESTINATION,
  }),

  deletedestination: (payload) => ({
    type: actions.DELETE_DESTINATION,
    payload: payload,
  }),

  adddeparture: (payload) => ({
    type: actions.ADD_DEPARTURE,
    payload: payload,
  }),

  getdeparture: (payload) => ({
    type: actions.GET_SINGLE_DEPARTURE,
    payload: payload,
  }),

  editdeparture: (payload) => ({
    type: actions.EDIT_DEPARTURE,
    payload: payload,
  }),

  getalldeparture: (payload) => ({
    type: actions.GET_ALL_DEPARTURE,
    payload: payload,
  }),
  getalldeparturecount: (payload) => ({
    type: actions.GET_ALL_DEPARTURE_COUNT,
    payload: payload,
  }),

  deletedeparture: (payload) => ({
    type: actions.DELETE_DEPARTURE,
    payload: payload,
  }),

  addpin: (payload) => ({
    type: actions.ADD_PIN,
    payload: payload,
  }),

  getallpin: (payload) => ({
    type: actions.GET_ALL_PIN,
    payload: payload,
  }),

  getpin: (payload) => ({
    type: actions.GET_SINGLE_PIN,
    payload: payload,
  }),

  editpin: (payload) => ({
    type: actions.EDIT_PIN,
    payload: payload,
  }),
  deletepin: (payload) => ({
    type: actions.DELETE_PIN,
    payload: payload,
  }),

  clearDepartureData: () => ({
    type: actions.CLEAR_DEPARTURE_DATA,
  }),
  cleardata: () => ({
    type: actions.CLEAR_DATA,
  }),
};

export default actions;
