export const variables = {
  primary_color: "#0000E3",
  sub_color_red: "#F82E33",
  sub_color_yellow: "#F8C339",
  sub_color_dark_gray: "#3C3C3C",
  sub_colot_gray: "#646464",
  sub_color_light_grey: "#C4C4C4",
  sub_color_off_white: "#E1E1E1",
  sub_color_white: "#FFFFFF",
};
