import styled from 'styled-components';
import WithDirection from '../../library/helpers/rtl';

const Page404StyleWrapper = styled.div`
  margin: 100px 0px 15px;
  @media screen and (max-width: 1800px) {
    margin: 80px 0px 15px;
  }
  @media screen and (max-width: 1600px) {
    margin: 70px 0px 0px;
  }
  @media screen and (max-width: 1440px) {
    margin: 60px 0px 0px;
  }
  @media screen and (max-width: 1199px) {
    margin: 50px 0px 0px;
  }
  @media screen and (max-width: 991px) {
    margin: 40px 0px 0px;
  }
  text-align: center;
  .image-wrapper {
    text-align: center;
    img {
      @media screen and (max-width: 1800px) {
        max-width: 600px;
      }
      @media screen and (max-width: 1440px) {
        max-width: 550px;
      }
      @media screen and (max-width: 1199px) {
        max-width: 500px;
      }
      @media screen and (max-width: 991px) {
        max-width: 450px;
      }
    }
  }
  .content-page {
    margin-top: 45px;
    @media screen and (max-width: 1600px) {
      margin-top: 40px;
    }
    @media screen and (max-width: 1440px) {
      margin-top: 35px;
    }
    @media screen and (max-width: 1199px) {
      margin-top: 30px;
    }
    p {
      margin: 15px 0px 45px;
      font-size: 16px;
      line-height: 19px;
      color: #2d2c2c;
      @media screen and (max-width: 1600px) {
        margin: 10px 0px 40px;
        font-size: 15px;
        line-height: 18px;
      }
      @media screen and (max-width: 1440px) {
        margin: 10px 0px 35px;
      }
      @media screen and (max-width: 1199px) {
        margin: 10px 0px 30px;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .fill {
      margin: auto;
    }
  }
`;
export default WithDirection(Page404StyleWrapper);
