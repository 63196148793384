import React, { useEffect } from 'react';
import { Form, Button, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import LoginStyleWrapper from './Login.styles';
import actions from '../../redux/auth/actions';
import { Navigate } from 'react-router-dom';
import { AppConstant, emailValidationRegex } from '../../config/constant';
import { BUTTON_LOGIN, LABEL_EMAIL, LABEL_PASSWORD } from '../../config/constant/language';

export default function Login() {
  const dispatch = useDispatch();
  const { login } = actions;
  const [loginForm] = Form.useForm();

  let idToken = useSelector((state) => state.Auth.idToken);
  let loginError = useSelector((state) => state.Auth.loginError);

  useEffect(() => {
    loginError !== '' && notification.error({ message: '', description: loginError });
  }, [loginError]);

  const onFinish = (value) => {
    dispatch(login(value));
  };
  return (
    <>
      {idToken && <Navigate to='/adminroutelist' />}
      <LoginStyleWrapper>
        <h1>α Route</h1>
        <Form form={loginForm} className='isoLoginForm' onFinish={onFinish}>
          <Form.Item
            name='email'
            label={LABEL_EMAIL}
            rules={[
              {
                required: true,
                message: AppConstant.FormValidation.emailRequired,
              },
              {
                pattern: emailValidationRegex,
                message: AppConstant.FormValidation.emailInvalid,
              },
            ]}>
            <Input placeholder='example@email.com' />
          </Form.Item>
          <Form.Item
            name='password'
            label={LABEL_PASSWORD}
            rules={[
              {
                required: true,
                message: AppConstant.FormValidation.passwordRequired,
              },
            ]}>
            <Input type='password' placeholder='password' />
          </Form.Item>
          <Button htmlType='submit' loading={false}>
            {BUTTON_LOGIN}
          </Button>
        </Form>
      </LoginStyleWrapper>
    </>
  );
}
