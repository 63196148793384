const actions = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGOUT: "LOGOUT",

  login: (payload) => ({
    type: actions.LOGIN,
    payload: payload,
  }),

  logout: () => ({
    type: actions.LOGOUT,
  }),
};

export default actions;
