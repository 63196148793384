import { app } from '../db/firebaseConnection'
import { Route } from '../models/route'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage'
const db = getFirestore(app)
export const updatePinpoint = async (id, data) => {
  try {
    console.log('updatePinpoint')
    if (data.image) {
      const storage = getStorage()
      const storagePath = `pin/${data.imageName}${Date.now()}`
      const storageRef = ref(storage, storagePath)
      let snapshot = await uploadString(storageRef, data.image, 'data_url')
      console.log('snapshot', snapshot)
      let imageurl = await getDownloadURL(snapshot.ref)
      console.log('imageurl', imageurl)
      data.image = imageurl
      data.storagePath = storagePath
    }
    console.log('data', data)
    return new Promise((resolve, reject) => {
      const newCityRef = doc(db, 'Pin', id)
      updateDoc(newCityRef, data)
        .then((data) => {
          console.log('Pin point Updated Successfully', data)
          resolve({
            error: false,
            Status: 200,
            message: 'Pin point updated successfully',
            data: data,
          })
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          console.log('Pin point Updation Failure!!!', errorCode, errorMessage)
          resolve({
            error: false,
            Status: 401,
            message: 'Pin point updation failed',
            data: errorCode,
          })
        })
    })
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: 'Internal Server Error',
      data: error.message,
    }
  }
}
