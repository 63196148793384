import GlobalStyles from "./assets/styles/globalStyle";
import "./assets/icons.css";

import React, { Suspense, useEffect } from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Loader from "./components/utility/loader";
import ErrorBoundary from "./ErrorBoundary";
import { SpinCustom } from "./components/uielements/spin";

import ListRoute from "./containers/ListRoute/ListRoute";
import Login from "./containers/Login/Login";
import Page404 from "./containers/Page404/Page404";
import AdminRouteList from "./containers/AdminRouteList/AdminRouteList";
import AddPin from "./containers/AddPin/AddPin";
import AddDestinationPoint from "./containers/AddDestinationPoint/AddDestinationPoint";
import AddDepaturePoint from "./containers/AddDepaturePoint/AddDepaturePoint";
import ViewRouteDetails from "./containers/ViewRouteDetails/ViewRouteDetails";

const PrivateRoutes = (props) => {
  let idToken = useSelector((state) => state.Auth.idToken);
  let auth = { token: idToken === null ? false : true };
  return auth.token ? <Outlet /> : <Navigate to="/admin" />;
};

const App = () => {
  console.log(process.env.REACT_APP_API_KEY)
  console.log(process.env.REACT_APP_GOOGLE_API_KEY)
  
  let idToken = useSelector((state) => state.Auth.idToken);
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/admin");
    }
  }, []);

  return (
    <div className="App">
      <GlobalStyles />
      <SpinCustom spinning={false}>
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route element={<PrivateRoutes idToken={idToken} />}>
                <Route
                  element={<AdminRouteList />}
                  path="/adminroutelist"
                  exact
                />
                <Route
                  element={<AddDestinationPoint />}
                  path="/adddestinationpoint"
                  exact
                />
                <Route
                  element={<AddDepaturePoint />}
                  path="/adddepaturepoint"
                  exact
                />
                <Route
                  element={<ViewRouteDetails />}
                  path="/viewroutedetails/:id"
                  exact
                />
                <Route
                  element={<AddDestinationPoint />}
                  path="/editdestinationpoint/:id"
                  exact
                />
                <Route
                  element={<AddDepaturePoint />}
                  path="/editdepaturepoint/:id"
                  exact
                />
                <Route
                  element={<AddDepaturePoint />}
                  path="/viewdepaturepoint/:id"
                  exact
                />
                <Route element={<AddPin />} path="/addpin/:id" exact />
              </Route>
              <Route element={<ListRoute />} path="/" exact />
              <Route element={<Login />} path="/admin" exact />
              <Route element={<Page404 />} path="*" />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </SpinCustom>
    </div>
  );
};

export default App;
