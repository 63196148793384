import {app} from "../db/firebaseConnection";
import {Route} from "../models/route"
import { collection,getFirestore,getDocs,query,collectionGroup ,where,orderBy} from "firebase/firestore";
const db = getFirestore(app);

export const getAlldestinationDeparturepointcount = async () => {
   
    try {
      console.log("getAllDeparturepoint");
      return new Promise(async(resolve, reject) => {
        const museums = query(collectionGroup(db, 'Destination'),orderBy("creationdate","desc")/*, where('destination_id', '==', destination_id)*/);
        let data=await getDocs(museums)
        console.log({data});
        const demo=[]
        data.forEach(i=>demo.push({...i.data(),id:i.id}))
      let pinData;
      let finalArray=[];
      for(let i=0;i<demo.length;i++)
      {
          const doc=demo[i]
          // console.log("data[i]",doc)
          const museums1 = query(collectionGroup(db, 'Route')/*,orderBy("creationdate","desc")*/,where('destination_id', '==', doc.id));
          let data1=await getDocs(museums1)
          console.log("data1",data1._snapshot.docChanges.length)
          
          finalArray.push({
            route_name:doc.route_name,
            destination_search:doc.destination_search,
            creationdate:doc.creationdate,
            destination_lat:doc.destination_lat,
            destination_lng:doc.destination_lng,
            destination_japan:doc.destination_japan,
            destination_english:doc.destination_english,
            postal_code:doc.postal_code,
            address:doc.address,
            id:doc.id,
            departurelength:data1._snapshot.docChanges.length
          })
      }
      
      console.log("finalarray",typeof finalArray)
      console.log("finalarray", finalArray)
      console.log("finalarray",finalArray.length)
        
        resolve({
            error: false,
            Status: 200,
            message: "Departure point fetched successfully!!!",
            data: finalArray,
          });
          
      // })
      // .catch(function (error) {
      //   // Handle Errors here.
      //   var errorCode = error.code;
      //   var errorMessage = error.message;
      //   console.log("Departure point Fecthed Failure!!!",errorCode,errorMessage)
      //   resolve({
      //     error: false,
      //     Status: 401,
      //     message: "Departure point fetched failed",
      //     data: errorCode,
      //   });
      // });
      })
    } catch (error) {
        return {
            error: true,
            Status: 501,
            message: "Internal Server Error",
            data: error.message,
          };
    }
};