import React, { useEffect, useState } from "react";
import { Button, Card, Table, Modal, notification } from "antd";
import { AdminLayoutStyleWrapper1 } from "../../assets/styles/globalStyle";
import MapContainer from "../../components/MapContainer/MapContainer";
import RouteTitleContainer from "../../components/RouteTitleContainer/RouteTitleContainer";
import ViewRouteDetailsStyleWrapper from "./ViewRouteDetails.styles";
import FooterButton from "../../components/FooterButton/FooterButton";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import actions from "../../redux/admin/actions";
import {
  BUTTON_ADD_DEPATURE,
  BUTTON_DETAIL,
  BUTTON_EDIT,
  BUTTON_EDIT_DESTINATION,
  BUTTON_ADD_PIN,
  COLUMN_ROUTE_DETAIL_1,
  COLUMN_ROUTE_DETAIL_2,
  COLUMN_ROUTE_DETAIL_3,
  TITLE_ADDRESS,
  TITLE_DESTINATION_NAME,
  TITLE_DESTINATION_NAME_EN,
  TITLE_ZIPCODE,
  MODAL_DELETE_BUTTON,
  MODAL_CANCEL_BUTTON,
  DEPARTURE_DELETE_MODAL_TEXT,
  DELETE_DEPARTURE_POINT,
} from "../../config/constant/language";
import { ThreeDots } from "react-loader-spinner";
import { getSingleDestination } from "../../library/services/api_services/viewSingleDestinationService";

export default function ViewRouteDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [destinationLatLng, setDestinationLatLng] = useState({
    lat: 0,
    lng: 0,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [destinationId, setDestinationId] = useState("");
  const { cleardata, getdestination, getalldeparture } = actions;
  const [departureDetails, setDepartureDetails] = useState([])

  let singleDestinationDetail = useSelector(
    (state) => state.Admin.singleDestinationDetail
  );
  let loading = useSelector((state) => state.Admin.loading);
  let addDepartureDetails = useSelector(
    (state) => state.Admin.addDepartureDetails
  );

  useEffect(() => {
    if (addDepartureDetails) {
      setDepartureDetails(addDepartureDetails.sort((a, b) => new Date(b.creationdate) - new Date(a.creationdate)))
    }
  }, [addDepartureDetails])
  
  useEffect(() => {
    if (id !== undefined) {
      getSingleDestination(id);
      dispatch(getdestination(id));
      dispatch(getalldeparture(id));
    }
  }, []); // eslint-disable-line  

  useEffect(() => {
    setDestinationLatLng({
      lat: singleDestinationDetail?.destination_lat
        ? singleDestinationDetail.destination_lat
        : 0,
      lng: singleDestinationDetail?.destination_lng
        ? singleDestinationDetail.destination_lng
        : 0,
    });
  }, [singleDestinationDetail]);

  useEffect(() => {
    // const id = localStorage.getItem('destination_id');
    setDestinationId(id);
    if (id) {
      localStorage.setItem("destination_id", id);
      dispatch(getdestination(id));
    } else {
      navigate("/adddestinationpoint");
    }
    const timeout = setTimeout(() => {
      dispatch(cleardata());
    }, 1000);
    return () => clearTimeout(timeout);
  }, [id]);

  const onAddDepartureClick = () => {
    localStorage.setItem("destination_id", id);
    navigate("/adddepaturepoint");
  };

  const onViewDepartureClick = (id) => {
    // console.log("iiiii");
    localStorage.setItem("departure_id", id);
    navigate(`/viewdepaturepoint/${id}`);
  };
  const columns = [
    {
      title: COLUMN_ROUTE_DETAIL_1,
      dataIndex: "departure_point",
    },
      {
      title: COLUMN_ROUTE_DETAIL_3,
      dataIndex: "exitLandmark",
    },
    {
      title: COLUMN_ROUTE_DETAIL_2,
      dataIndex: "transportation",
    },
    {
      title: "",
      render: (text, record, index) => (
        <>
          <Button onClick={() => onViewDepartureClick(record.key)}>
            {BUTTON_DETAIL}
          </Button>
          <Button onClick={() => navigate(`/editdepaturepoint/${record.key}`)}>
            {BUTTON_EDIT}
          </Button>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRoutes(selectedRowKeys);
    },
  };

  const onDeleteDeparturePointClick = () => {
    if (selectedRoutes.length > 0) {
      setDeleteModal(true);
    } else {
      notification.error({ message: "Please select rooute first" });
    }
  };
  // console.log({singleDestinationDetail});

  const onModalDeleteClick = () => {
    let payload = {
      selectedRoutes: selectedRoutes,
      id: id,
    };
    dispatch(actions.deletedeparture(payload));
    setSelectedRoutes([]);
    setDeleteModal(false);
  };
  return (
    <AdminLayoutStyleWrapper1>
      <ViewRouteDetailsStyleWrapper>
        <Card className="route-details-card">
          <div className="left-wrapper">
            <RouteTitleContainer
              routeName={singleDestinationDetail.route_name}
            ></RouteTitleContainer>
            {/* <div className="content-container"> */}
            <div className="destination-border">
              <div className="header-btn">
                <Button onClick={() => navigate(`/editdestinationpoint/${id}`)}>
                  {BUTTON_EDIT_DESTINATION}
                </Button>
              </div>
              <div className="destination-info">
                <div>
                  <p>{TITLE_DESTINATION_NAME}</p>
                  <p>{singleDestinationDetail.destination_japan}</p>
                </div>
                <div>
                  <p>{TITLE_DESTINATION_NAME_EN}</p>
                  <p>{singleDestinationDetail.destination_english}</p>
                </div>
                <div>
                  <p>{TITLE_ZIPCODE}</p>
                  <p>
                    {singleDestinationDetail.postal_code === ""
                      ? "N/A"
                      : singleDestinationDetail.postal_code}
                  </p>
                </div>
                <div>
                  <p>{TITLE_ADDRESS}</p>
                  <p>{singleDestinationDetail.address}</p>
                </div>
              </div>
              <div>
                
              </div>
            </div>
              {/* <div className="add-destination-save">
                <Button onClick={() => navigate(`/editdestinationpoint/${id}`)}>
                  {BUTTON_EDIT_DESTINATION}
                </Button>
              </div>
              <div className="add-destination-form-container">
                <div>
                  <p>{TITLE_DESTINATION_NAME}</p>
                  <p>{singleDestinationDetail.destination_japan}</p>
                </div>
                <div>
                  <p>{TITLE_DESTINATION_NAME_EN}</p>
                  <p>{singleDestinationDetail.destination_english}</p>
                </div>
                <div>
                  <p>{TITLE_ZIPCODE}</p>
                  <p>
                    {singleDestinationDetail.postal_code === ""
                      ? "N/A"
                      : singleDestinationDetail.postal_code}
                  </p>
                </div>
                <div>
                  <p>{TITLE_ADDRESS}</p>
                  <p>{singleDestinationDetail.address}</p>
                </div>
              </div> */}
            {/* </div> */}
              <MapContainer marker={destinationLatLng} zoomLevel={12}></MapContainer>
          </div>
          <div className="right-wrapper view-route-right-wrapper">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "calc(100vh/2)",
                }}
              >
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#000"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            ) : (
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={departureDetails}
                pagination={false}
                scroll={{
                  y: "calc(65vh)",
                }}
              />
            )
            }
            <div className="button-wrapper">
              <Button onClick={() => onDeleteDeparturePointClick()}>
                {DELETE_DEPARTURE_POINT}
              </Button>
              <Button
                className="addDeparturePoint"
                onClick={() => onAddDepartureClick()}
              >
                {BUTTON_ADD_DEPATURE}
              </Button>
            </div>
          </div>
        </Card>
        <Modal
          centered
          closable={false}
          visible={deleteModal}
          onOk={onModalDeleteClick}
          onCancel={() => setDeleteModal(false)}
          okText={MODAL_DELETE_BUTTON}
          cancelText={MODAL_CANCEL_BUTTON}
        >
          <h2>{DEPARTURE_DELETE_MODAL_TEXT}</h2>
        </Modal>
      </ViewRouteDetailsStyleWrapper>
      <FooterButton isViewRoutePage={true}></FooterButton>
    </AdminLayoutStyleWrapper1>
  );
}
