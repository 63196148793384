import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import { loginUserwithemailandpassword } from "../../library/services/api_services/loginService";

export function* login() {
  yield takeEvery(actions.LOGIN, function* ({ payload }) {
    try {
      const response = yield call(
        loginUserwithemailandpassword,
        payload.email,
        payload.password
      );
      if (response.Status === 200) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          idToken: response.data,
        });
      } else {
        yield put({
          type: actions.LOGIN_ERROR,
          loginError: response.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOGIN_ERROR,
        loginError: "error from catch block.",
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.clear();
    yield localStorage.setItem("id_token", payload.idToken);
  });
}

export default function* rootSaga() {
  yield all([fork(login), fork(loginSuccess)]);
}
