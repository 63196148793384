// import actions from './action';

const initState = {
  globalLoader: 0
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case 'actions.CLEAR_MENU':
      return {
        ...state
      };
    default:
      return state;
  }
}
