import {app} from "../db/firebaseConnection";
import {Destination} from "../models/destination"
import { collection, doc, setDoc ,getFirestore ,addDoc } from "firebase/firestore";
const db = getFirestore(app);
export const addDestination = async (data) => {
    try {
      console.log("Add Destination");
      data={...data,creationdate:new Date().toISOString()}
      console.log("final Object After adding date",data)
      return new Promise(async(resolve, reject) => {
      const newCityRef = collection(db, "Destination");
      addDoc(newCityRef, data).then((result) => {
        console.log("Data Added Successfully",result.id)
        resolve({
          error: false,
          Status: 200,
          message: "Record created successfully",
          data: result.id,
        });
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("Document Adding Failure!!!",errorCode,errorMessage)
        resolve({
          error: false,
          Status: 401,
          message: "Document creation failed",
          data: errorCode,
        });
      });
      })
    } catch (error) {
        return {
            error: true,
            Status: 501,
            message: "Internal Server Error",
            data: error.message,
          };
    }
};