import { app } from "../db/firebaseConnection";
import { Route } from "../models/route";
import {
  collection,
  doc,
  setDoc,
  getFirestore,
  addDoc,
} from "firebase/firestore";
const db = getFirestore(app);
export const addDeparturepoint = async (data) => {
  try {
    console.log("Add Destination");
    data = { ...data, creationdate: new Date().toISOString() }
    console.log("final Object After adding date", data)
    return new Promise((resolve, reject) => {
      const newCityRef = collection(db, "Route");
      addDoc(newCityRef, data)
        .then((data) => {
          resolve({
            error: false,
            Status: 201,
            message: "Departure point created successfully",
            data: data.id,
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;

          resolve({
            error: false,
            Status: 401,
            message: "Departure point creation failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
