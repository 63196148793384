import { app } from "../db/firebaseConnection";
import { Destination } from "../models/destination";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
const db = getFirestore(app);
export const updateDestination = async (id, data) => {
  try {
    //   let data = {
    //   destination_japan : "DEMO_JAPAN",
    //   destination_english : "DEMO_ENGLISH",
    //   postal_code : "DEMO_POSTALCODE",
    //   address : "DEMO_ADDRESS",
    //   };
    return new Promise((resolve, reject) => {
      const newCityRef = doc(db, "Destination", id);
      updateDoc(newCityRef, data)
        .then((data) => {
          resolve({
            error: false,
            Status: 200,
            message: "Record updated successfully",
            data: data,
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          resolve({
            error: false,
            Status: 401,
            message: "Document updation failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
