import styled from "styled-components";
import WithDirection from "../../library/helpers/rtl";
import { variables } from "../../assets/styles/variables";

const RouteTitleContainerStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${variables.sub_color_light_grey};
  p {
    text-align: center;
    margin: 0px;
    font-size: 18px;
  }
  .ant-form{
    width:40%;
    align-self: center;
  }
`;
export default WithDirection(RouteTitleContainerStyleWrapper);
