import {app} from "../db/firebaseConnection";
import {Route} from "../models/route"
import { collection,getFirestore,getDocs,query,collectionGroup ,where,orderBy} from "firebase/firestore";
const db = getFirestore(app);

export const getAllPinpoint = async (routeinfo_id) => {
    try {
      console.log("getAllPinpoint");
      return new Promise((resolve, reject) => {
        const museums = query(collectionGroup(db, 'Pin'),/*orderBy("creationdate"),*/where('routeinfo_id', '==', routeinfo_id));
    getDocs(museums).then((data) => {
        console.log("Pin point Fetched Successfully",data)
        resolve({
            error: false,
            Status: 200,
            message: "Pin point fetched successfully",
            data: data,
          });
          data.forEach((doc) => {
            console.log(doc.id, ' => ', doc.data());
          });
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("Pin point Fecthed Failure!!!",errorCode,errorMessage)
        resolve({
          error: false,
          Status: 401,
          message: "Pin point fetched failed",
          data: errorCode,
        });
      });
      })
    } catch (error) {
        return {
            error: true,
            Status: 501,
            message: "Internal Server Error",
            data: error.message,
          };
    }
};