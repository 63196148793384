import { app } from '../db/firebaseConnection'
import { doc, getFirestore, deleteDoc, getDoc } from 'firebase/firestore'
import { getStorage, ref, deleteObject } from 'firebase/storage'
const db = getFirestore(app)

export const deletePinpoint = async (id) => {
  try {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < id.length; i++) {
        const pinRef = doc(db, 'Pin', id[i])

        const snapshot = await getDoc(pinRef)
        const path = snapshot.data().storagePath
        deleteDoc(pinRef)
          .then(async (data) => {
            const storage = getStorage()
            const deleteRef = ref(storage, path)
            await deleteObject(deleteRef)

            resolve({
              error: false,
              Status: 200,
              message: 'Record deleted successfully',
              data: data,
            })
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message

            resolve({
              error: false,
              Status: 401,
              message: 'Document deletion failed',
              data: errorCode,
            })
          })
      }
      resolve({
        error: false,
        Status: 200,
        message: 'Records deleted successfully',
        data: '',
      })
    })
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: 'Internal Server Error',
      data: error.message,
    }
  }
}
