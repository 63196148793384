import { Button } from "antd";
import React from "react";
import AdminHeaderStyleWrapper from "./AdminHeader.styles";
import { useNavigate } from "react-router-dom";
import actions from "../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { BUTTON_LOGOUT } from "../../config/constant/language";

export default function AdminHeader() {
  const dispatch = useDispatch();
  const { logout } = actions;

  const navigate = useNavigate();

  const onLogoutClick = async () => {
    await localStorage.clear();
    dispatch(logout());
    navigate("/admin");
  };

  return (
    <AdminHeaderStyleWrapper>
      <Button onClick={onLogoutClick} className="logout-btn">
        {BUTTON_LOGOUT}
      </Button>
    </AdminHeaderStyleWrapper>
  );
}
