export const mapStyles = 
[
  // {
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       color: '#f5f5f5',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.icon',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#616161',
  //     },
  //   ],
  // },
  // {
  //   elementType: 'labels.text.stroke',
  //   stylers: [
  //     {
  //       color: '#f5f5f5',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'administrative',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'administrative.land_parcel',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#bdbdbd',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'landscape',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi',
  //   stylers: [
  //     {
  //       color: '#646464',
  //     },
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       color: '#eeeeee',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#757575',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi.park',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       color: '#e5e5e5',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'poi.park',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#9e9e9e',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road',
  //   stylers: [
  //     {
  //       visibility: 'simplified',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       color: '#ffffff',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road.arterial',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#757575',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road.highway',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       color: '#dadada',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road.highway',
  //   elementType: 'labels',
  //   stylers: [
  //     {
  //       visibility: 'off',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road.highway',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#616161',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'road.local',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#9e9e9e',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'transit.line',
  //   elementType: 'geometry',
  //   stylers: [
  //     {
  //       color: '#e5e5e5',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'transit.line',
  //   elementType: 'geometry.fill',
  //   stylers: [
  //     {
  //       color: '#8c8c8c',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'transit.line',
  //   elementType: 'geometry.stroke',
  //   stylers: [
  //     {
  //       color: '#8c8c8c',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'transit.station',
  //   stylers: [
  //     {
  //       visibility: 'simplified',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'water',
  //   stylers: [
  //     {
  //       color: '#c2c2c2',
  //     },
  //     {
  //       visibility: 'simplified',
  //     },
  //   ],
  // },
  // {
  //   featureType: 'water',
  //   elementType: 'labels.text.fill',
  //   stylers: [
  //     {
  //       color: '#9e9e9e',
  //     },
  //   ],
  // },
];