import { combineReducers } from 'redux';

import App from '../app/reducer';
import Auth from '../auth/reducer';
import Admin from '../admin/reducer';

const rootReducer = combineReducers({
  App,
  Auth,
  Admin,
});

export default rootReducer;
