import styled from 'styled-components';
import WithDirection from '../../library/helpers/rtl';
import { variables } from '../../assets/styles/variables';

const AddPinStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
  .pin-container {
    // display: flex;
    // justify-content: center;
    width: 100%;
    flex-container: column;
    justify-content: space-between;
    border-top: 1px solid ${variables.sub_color_light_grey};
    .ant-btn {
      margin: 20px;
      width: auto;
    }
  }
  .zoomLevel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0px;
    .ant-select {
      max-width: 50%;
    }
  }
  .button-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around !important;
      border-bottom: 1px solid ${variables.sub_color_light_grey};
      .ant-btn {
        width: 40%;
        margin: 10px 50px;
      }
    } 
  .ant-table-wrapper {
    // border-top: 1px solid ${variables.sub_color_light_grey};
    // border-bottom: 1px solid ${variables.sub_color_light_grey};
    .ant-table-thead {
      display: none;
    }
    .ant-table-tbody {
      td {
        img {
          max-width: 90px;
          height: 50px;;
        }
        .ant-btn {
          margin: 0px;
          min-width: 70px;
        }
      }
    }
  }
  .img-card{
    max-width: 400px;
    min-width: 400px;
    max-height: 500px;
    min-height: 500px;
  }
`;
export default WithDirection(AddPinStyleWrapper);
