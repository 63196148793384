import { GlobalStyleComponent, DefaultTheme, StyledComponent } from "styled-components";

let direction : string| null = 'ltr';
if (typeof window !== 'undefined') {
  direction = document.getElementsByTagName('html')[0].getAttribute('dir');
}
const withDirection = (Component: GlobalStyleComponent<{}, DefaultTheme> | StyledComponent<any, any, object, string | number | symbol>) => (props: any) => {
  return <Component {...props} data-rtl={direction} />;
};

export default withDirection;
export { direction };
