import React from "react";
import RouteTitleContainerStyleWrapper from "./RouteTitleContainer.styles";
import { TITLE_ROUTE_NAME } from "../../config/constant/language";
import { Form, Input } from "antd";
import { AppConstant } from "../../config/constant";

export default function RouteTitleContainer(props) {
  return (
    <RouteTitleContainerStyleWrapper>
      <p>{TITLE_ROUTE_NAME}</p>
      {props && props.isEditable ? (
        <Form form={props.formName}>
          <Form.Item
            name="route_name"
            rules={[
              {
                required: true,
                message: AppConstant.FormValidation.routeNameRequired,
              },
            ]}
            colon={false}
          >
            <Input placeholder="路線名"></Input>
          </Form.Item>
        </Form>
      ) : props.routeName ? (
        <p>{props.routeName}</p>
      ) : (
        <p>帝国ホテル</p>
      )}
    </RouteTitleContainerStyleWrapper>
  );
}
