import React, { Component } from 'react';
import somethingWrong from './assets/images/something-went-wrong.jpg';
import { WrongContainer } from './assets/styles/globalStyle';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <WrongContainer className="wrong-wrapper">
          <div className="wrong-wrapper-content">
            <img src={somethingWrong} alt="" />
            <h1>Oops! Something went wrong</h1>
            <p>Brace yourself till we get the error fixed</p>
          </div>
        </WrongContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
