import { app } from '../db/firebaseConnection'
// import { getFirestore } from 'firebase/firestore';
import { collection, doc, setDoc, getFirestore, addDoc } from 'firebase/firestore'
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage'
const db = getFirestore(app)

export const fileUploadService = async (data) => {
  // console.log('Data',data);
  const storage = getStorage()
  const storagePath = `pin/${data.imageName}${Date.now()}`
  const storageRef = ref(storage, storagePath)

  let snapshot = await uploadString(storageRef, data.image, 'data_url')
  console.log('snapshot', snapshot)
  let imageurl = await getDownloadURL(snapshot.ref)
  console.log('imageurl', imageurl)
  let finalObject = {
    routeinfo_id: data.routeinfo_id,
    pin: data.pin,
    orientation: data.orientation,
    image: imageurl
      ? imageurl
      : 'https://firebasestorage.googleapis.com/v0/b/alpharoutedev.appspot.com/o/pin%2F1666354500728?alt=media&token=ba9881d9-8838-49b5-b9c0-fb61283f73ec',
    pin_lat: data.pin_lat,
    pin_lng: data.pin_lng,
    storagePath,
    creationdate: new Date().toISOString(),
  }
  return new Promise((resolve, reject) => {
    const newCityRef = collection(db, 'Pin')
    addDoc(newCityRef, finalObject)
      .then((data) => {
        console.log('Pin point Added Successfully', data.id)
        resolve({
          error: false,
          Status: 201,
          message: 'Pin point created successfully',
          data: data.id,
        })
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        console.log('Pin point Adding Failure!!!', errorCode, errorMessage)
        resolve({
          error: false,
          Status: 401,
          message: 'Pin point creation failed',
          data: errorCode,
        })
      })
  })
}
