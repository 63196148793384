import { app } from "../db/firebaseConnection";
import { Route } from "../models/route";
import { doc, getFirestore, getDoc } from "firebase/firestore";
const db = getFirestore(app);

export const getSinglePinpoint = async (id) => {
  try {
    return new Promise((resolve, reject) => {
      const newCityRef = doc(db, "Pin", id);
      getDoc(newCityRef)
        .then((data) => {
          if (data.exists()) {
            resolve({
              error: false,
              Status: 200,
              message: "Pin point fetched successfully",
              data: data.data(),
            });
          } else {
            // doc.data() will be undefined in this case
            resolve({
              error: false,
              Status: 404,
              message: "No record found",
              data: "",
            });
          }
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          resolve({
            error: false,
            Status: 401,
            message: "Document fetched failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
