// Form validation constants
export const AppConstant = {
  FormValidation: {
    emailRequired: 'メールアドレスが必要です。',
    emailInvalid: '有効なメールアドレスを入力してください。',
    passwordRequired: 'パスワードが必要です。',
    destinationSearchRequired: 'Destination search required.',
    destinationNameRequired: '宛先名が必要です。',
    routeNameRequired: 'ルート名が必要です。',
    transportationRequired: 'Transportation is required.',
    departureSearchRequired: 'Search departure point is required.',
    routeInformationRequired: 'Route information is required.',
    departurePointRequired: 'Departure point is required.',
    exitLandmarkRequired: 'exit land mark required.',
    postalCodeRequired: 'Postal code is required.',
    addressRequired: 'Address is required.',
    routeInfoRequired: 'Route information is required.',
  },
};

export const emailValidationRegex = /^\w+([.]?\w+)@\w+(\w+)(.\w{2,3})+$/;
