import React, { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Space, Spin } from 'antd'
import { GoogleMap, DirectionsRenderer, DirectionsService, useJsApiLoader, Marker } from '@react-google-maps/api'
import Marker1 from '../../assets/icons/s/1.svg'
import Marker2 from '../../assets/icons/s/2.svg'
import Marker3 from '../../assets/icons/s/3.svg'
import Marker4 from '../../assets/icons/s/4.svg'
import Marker5 from '../../assets/icons/s/5.svg'
import Marker6 from '../../assets/icons/s/6.svg'
import Marker7 from '../../assets/icons/s/7.svg'
import Marker8 from '../../assets/icons/s/8.svg'
import Marker9 from '../../assets/icons/s/9.svg'
import Marker10 from '../../assets/icons/s/10.svg'
import Marker11 from '../../assets/icons/s/11.svg'
import Marker12 from '../../assets/icons/s/12.svg'
import Marker13 from '../../assets/icons/s/13.svg'
import Marker14 from '../../assets/icons/s/14.svg'
import Marker15 from '../../assets/icons/s/15.svg'
import Marker16 from '../../assets/icons/s/16.svg'
import Marker17 from '../../assets/icons/s/17.svg'
import Marker18 from '../../assets/icons/s/18.svg'
import Marker19 from '../../assets/icons/s/19.svg'
import Marker20 from '../../assets/icons/s/20.svg'
import Marker21 from '../../assets/icons/s/21.svg'
import Marker22 from '../../assets/icons/s/22.svg'
import Marker23 from '../../assets/icons/s/23.svg'
import Marker24 from '../../assets/icons/s/24.svg'
import Marker25 from '../../assets/icons/s/25.svg'
import Marker26 from '../../assets/icons/s/26.svg'
import Marker27 from '../../assets/icons/s/27.svg'
import Marker28 from '../../assets/icons/s/28.svg'
import Marker29 from '../../assets/icons/s/29.svg'
import Marker30 from '../../assets/icons/s/30.svg'
import Marker31 from '../../assets/icons/s/31.svg'
import Marker32 from '../../assets/icons/s/32.svg'
import Marker33 from '../../assets/icons/s/33.svg'
import Marker34 from '../../assets/icons/s/34.svg'
import Marker35 from '../../assets/icons/s/35.svg'
import Marker36 from '../../assets/icons/s/36.svg'
import Marker37 from '../../assets/icons/s/37.svg'
import Marker38 from '../../assets/icons/s/38.svg'
import Marker39 from '../../assets/icons/s/39.svg'
import Marker40 from '../../assets/icons/s/30.svg'
import Marker41 from '../../assets/icons/s/41.svg'
import Marker42 from '../../assets/icons/s/42.svg'
import Marker43 from '../../assets/icons/s/43.svg'
import Marker44 from '../../assets/icons/s/44.svg'
import Marker45 from '../../assets/icons/s/45.svg'
import Marker46 from '../../assets/icons/s/46.svg'
import Marker47 from '../../assets/icons/s/47.svg'
import Marker48 from '../../assets/icons/s/48.svg'
import Marker49 from '../../assets/icons/s/49.svg'
import Marker50 from '../../assets/icons/s/50.svg'
import Marker51 from '../../assets/icons/s/51.svg'
import Marker52 from '../../assets/icons/s/52.svg'
import Marker53 from '../../assets/icons/s/53.svg'
import Marker54 from '../../assets/icons/s/54.svg'
import Marker55 from '../../assets/icons/s/55.svg'
import Marker56 from '../../assets/icons/s/56.svg'
import Marker57 from '../../assets/icons/s/57.svg'
import Marker58 from '../../assets/icons/s/58.svg'
import Marker59 from '../../assets/icons/s/59.svg'
import Marker60 from '../../assets/icons/s/60.svg'
import Marker61 from '../../assets/icons/s/61.svg'
import Marker62 from '../../assets/icons/s/62.svg'
import Marker63 from '../../assets/icons/s/63.svg'
import Marker64 from '../../assets/icons/s/64.svg'
import Marker65 from '../../assets/icons/s/65.svg'
import Marker66 from '../../assets/icons/s/66.svg'
import Marker67 from '../../assets/icons/s/67.svg'
import Marker68 from '../../assets/icons/s/68.svg'
import Marker69 from '../../assets/icons/s/69.svg'
import Marker70 from '../../assets/icons/s/70.svg'
import Marker71 from '../../assets/icons/s/71.svg'
import Marker72 from '../../assets/icons/s/72.svg'
import Marker73 from '../../assets/icons/s/73.svg'
import Marker74 from '../../assets/icons/s/74.svg'
import Marker75 from '../../assets/icons/s/75.svg'
import Marker76 from '../../assets/icons/s/76.svg'
import Marker77 from '../../assets/icons/s/77.svg'
import Marker78 from '../../assets/icons/s/78.svg'
import Marker79 from '../../assets/icons/s/79.svg'
import Marker80 from '../../assets/icons/s/80.svg'
import Marker81 from '../../assets/icons/s/81.svg'
import Marker82 from '../../assets/icons/s/82.svg'
import Marker83 from '../../assets/icons/s/83.svg'
import Marker84 from '../../assets/icons/s/84.svg'
import Marker85 from '../../assets/icons/s/85.svg'
import Marker86 from '../../assets/icons/s/86.svg'
import Marker87 from '../../assets/icons/s/87.svg'
import Marker88 from '../../assets/icons/s/88.svg'
import Marker89 from '../../assets/icons/s/89.svg'
import Marker90 from '../../assets/icons/s/90.svg'
import Marker91 from '../../assets/icons/s/91.svg'
import Marker92 from '../../assets/icons/s/92.svg'
import Marker93 from '../../assets/icons/s/93.svg'
import Marker94 from '../../assets/icons/s/94.svg'
import Marker95 from '../../assets/icons/s/95.svg'
import Marker96 from '../../assets/icons/s/96.svg'
import Marker97 from '../../assets/icons/s/97.svg'
import Marker98 from '../../assets/icons/s/98.svg'
import Marker99 from '../../assets/icons/s/99.svg'

import MapContainerStyleWrapper from './MapContainer.styles'
import { useEffect } from 'react'
import actions from '../../redux/admin/actions'
import Geocode from 'react-geocode'
import { useLocation } from 'react-router-dom'
import { mapStyles } from '../MapStyles/Mapstyle'
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY)
Geocode.enableDebug()

const library = ['places']
export default function MapContainer(props) {
  const center = { lat: parseFloat(36.2048), lng: parseFloat(138.2529) }
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: library,
  })

  const ref = useRef()
  const location = useLocation()

  const [map, setMap] = useState(null)
  const [wpt, setWpt] = useState([])
  const [dragCall, setDragCall] = useState(false)
  // const [destination, setDestination] = useState('0,0');
  // const [zoomLevel, setZoomLevel] = useState(props?.zoomLevel || 12);

  const [currentDirection, setCurrentDirection] = useState(null)

  const distanceRef = useRef('')
  const durationRef = useRef('')
  const waypointsRef = useRef([])
  const directionRef = useRef()
  const count = useRef(0)
  const dispatch = useDispatch()
  const { getallpin, editpin, deletepin, addpin, getpin } = actions
  // const fixedMarkers = useRef([{ lat: 0, lng: 0 }]);
  const validZoomLevels = [15]

  let addPinDetails = useSelector((state) => state.Admin.addPinsDetails)

  // useEffect(() => {
  //   if(props?.origin){
  //     setOrigin(props?.origin); // need to fix this
  //   }
  //   return ()=>{
  //     setOrigin('0,0');
  //   }
  // }, [props?.origin]);

  // useEffect(() => {
  //   if(props?.destination){
  //     setDestination(props?.destination);
  //   }
  //   return ()=>{
  //     setDestination('0,0');
  //   }
  // }, [props?.destination]);

  useEffect(() => {
    if (props?.pathLtLg?.length) {
      // console.log("###OPOPOP", props?.pathLtLg);
      setWpt(props?.pathLtLg) // need to fix this
    }
    return () => {
      setWpt([])
    }
  }, [props?.pathLtLg])

  useEffect(() => {
    setDragCall(false)
  }, [])

  useEffect(() => {
    if (props?.onAddPinCall === true) {
      setDragCall(true)
    }
    console.log('OnAddPinCall', props?.onAddPinCall)
  }, [props?.onAddPinCall])

  // useEffect(() => {
  //   if (props?.marker?.lat === 0) {
  //     setZoomLevel(12);
  //   }
  //   if (props?.zoomLevel) {
  //     setZoomLevel(props?.zoomLevel);
  //   }
  // }, [props?.zoomLevel]);

  if (!isLoaded) {
    return (
      <Space size='middle'>
        <Spin size='large' />
      </Space>
    )
  }
  const onDragEnd = (e, index) => {
    setDragCall(true)
    if (location.pathname.includes('addpin')) {
      let newLat = parseFloat(e.latLng.lat())
      let newLng = parseFloat(e.latLng.lng())
      let payload = {
        pin_lat: newLat,
        pin_lng: newLng,
      }
      addPinDetails[index].pin_lat = newLat
      addPinDetails[index].pin_lng = newLng

      let data = { id: addPinDetails[index].key, payload: payload }
      dispatch(editpin(data))
    }
  }

  const onChangeDirection = (waypoints, distance, duration) => {
    waypointsRef.current = waypoints
    distanceRef.current = distance
    durationRef.current = duration
  }

  const directionsCallback = (res) => {
    if (res !== null && count.current < 1) {
      if (res.status === 'OK') {
        count.current += 1
        setCurrentDirection(res)
      } else {
        count.current = 0
        console.log('res: ', res)
      }
    }
  }

  // const directionsCallback = (result) => {
  //   if (result.status === "OK") {
  //     setCurrentDirection(result);
  //   }
  // };

  const onDirectionsChanged = () => {
    if (ref.current) {
      setDragCall(true)
      const directions = ref.current.getDirections()

      const array = []
      for (const point of directions?.routes[0]?.legs[0]?.via_waypoint) {
        array.push({
          location: { lat: point.location.lat(), lng: point.location.lng() },
          stopover: false,
        })
      }
      props?.PathChangeDetails({
        pathLtLg: array,
      })
      props?.onRouteInfoChange(directions)

      setWpt(array)

      const distance = directions.routes[0].legs[0].distance.text
      const duration = directions.routes[0].legs[0].duration.text
      onChangeDirection(array, distance, duration)
    }
  }

  const renderMapWithoutDirection = () => {
    return (
      <GoogleMap
        center={props?.marker?.lat === 0 ? center : props?.marker}
        zoom={props?.zoomLevel}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        onLoad={(map) => setMap(map)}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          styles: mapStyles,
        }}>
        {props?.marker?.lat === 0 ? '' : <Marker position={props?.marker} />}
      </GoogleMap>
    )
  }

  const renderMapWithDirrection = () => {
    return (
      <GoogleMap
        id='map'
        mapContainerStyle={{ height: '100%', width: '100%' }}
        onLoad={(map) => setMap(map)}
        options={{
          // draggable: true,
          fullscreenControl: false,
          streetViewControl: false,
          styles: mapStyles,
        }}>
        {!dragCall && count.current < 1 && (
          <DirectionsService
            options={{
              origin: props?.origin,
              destination: props?.destination,
              travelMode: props?.travellingMode,
              optimizeWaypoints: false,
              waypoints: wpt,
            }}
            callback={(e) => directionsCallback(e)}
          />
        )}
        {props?.isAddPin === true ? (
          <>
            {props?.allPins.map((marker, index) => {
              let lat = parseFloat(marker.pin_lat)
              let lng = parseFloat(marker.pin_lng)
              const latLng = { lat, lng }
              let iconName
              switch (index + 1) {
                case 1:
                  iconName = Marker1
                  break
                case 2:
                  iconName = Marker2
                  break
                case 3:
                  iconName = Marker3
                  break
                case 4:
                  iconName = Marker4
                  break
                case 5:
                  iconName = Marker5
                  break
                case 6:
                  iconName = Marker6
                  break
                case 7:
                  iconName = Marker7
                  break
                case 8:
                  iconName = Marker8
                  break
                case 9:
                  iconName = Marker9
                  break
                case 10:
                  iconName = Marker10
                  break
                case 11:
                  iconName = Marker11
                  break
                case 12:
                  iconName = Marker12
                  break
                case 13:
                  iconName = Marker13
                  break
                case 14:
                  iconName = Marker14
                  break
                case 15:
                  iconName = Marker15
                  break
                case 16:
                  iconName = Marker16
                  break
                case 17:
                  iconName = Marker17
                  break
                case 18:
                  iconName = Marker18
                  break
                case 19:
                  iconName = Marker19
                  break
                case 20:
                  iconName = Marker20
                  break
                case 21:
                  iconName = Marker21
                  break
                case 22:
                  iconName = Marker22
                  break
                case 23:
                  iconName = Marker23
                  break
                case 24:
                  iconName = Marker24
                  break
                case 25:
                  iconName = Marker25
                  break
                case 26:
                  iconName = Marker26
                  break
                case 27:
                  iconName = Marker27
                  break
                case 28:
                  iconName = Marker28
                  break
                case 29:
                  iconName = Marker29
                  break
                case 30:
                  iconName = Marker30
                  break
                case 31:
                  iconName = Marker31
                  break
                case 32:
                  iconName = Marker32
                  break
                case 33:
                  iconName = Marker33
                  break
                case 34:
                  iconName = Marker34
                  break
                case 35:
                  iconName = Marker35
                  break
                case 36:
                  iconName = Marker36
                  break
                case 37:
                  iconName = Marker37
                  break
                case 38:
                  iconName = Marker38
                  break
                case 39:
                  iconName = Marker39
                  break
                case 40:
                  iconName = Marker40
                  break
                case 41:
                  iconName = Marker41
                  break
                case 42:
                  iconName = Marker42
                  break
                case 43:
                  iconName = Marker43
                  break
                case 44:
                  iconName = Marker44
                  break
                case 45:
                  iconName = Marker45
                  break
                case 46:
                  iconName = Marker46
                  break
                case 47:
                  iconName = Marker47
                  break
                case 48:
                  iconName = Marker48
                  break
                case 49:
                  iconName = Marker49
                  break
                case 50:
                  iconName = Marker50
                  break
                case 51:
                  iconName = Marker51
                  break
                case 52:
                  iconName = Marker52
                  break
                case 53:
                  iconName = Marker53
                  break
                case 54:
                  iconName = Marker54
                  break
                case 55:
                  iconName = Marker55
                  break
                case 56:
                  iconName = Marker56
                  break
                case 57:
                  iconName = Marker57
                  break
                case 58:
                  iconName = Marker58
                  break
                case 59:
                  iconName = Marker59
                  break
                case 60:
                  iconName = Marker60
                  break
                case 61:
                  iconName = Marker61
                  break
                case 62:
                  iconName = Marker62
                  break
                case 63:
                  iconName = Marker63
                  break
                case 64:
                  iconName = Marker64
                  break
                case 65:
                  iconName = Marker65
                  break
                case 66:
                  iconName = Marker66
                  break
                case 67:
                  iconName = Marker67
                  break
                case 68:
                  iconName = Marker68
                  break
                case 69:
                  iconName = Marker69
                  break
                case 70:
                  iconName = Marker70
                  break
                case 71:
                  iconName = Marker71
                  break
                case 72:
                  iconName = Marker72
                  break
                case 73:
                  iconName = Marker73
                  break
                case 74:
                  iconName = Marker74
                  break
                case 75:
                  iconName = Marker75
                  break
                case 76:
                  iconName = Marker76
                  break
                case 77:
                  iconName = Marker77
                  break
                case 78:
                  iconName = Marker78
                  break
                case 79:
                  iconName = Marker79
                  break
                case 80:
                  iconName = Marker80
                  break
                case 81:
                  iconName = Marker81
                  break
                case 82:
                  iconName = Marker82
                  break
                case 83:
                  iconName = Marker83
                  break
                case 84:
                  iconName = Marker84
                  break
                case 85:
                  iconName = Marker85
                  break
                case 86:
                  iconName = Marker86
                  break
                case 87:
                  iconName = Marker87
                  break
                case 88:
                  iconName = Marker88
                  break
                case 89:
                  iconName = Marker89
                  break
                case 90:
                  iconName = Marker90
                  break
                case 91:
                  iconName = Marker91
                  break
                case 92:
                  iconName = Marker92
                  break
                case 93:
                  iconName = Marker93
                  break
                case 94:
                  iconName = Marker94
                  break
                case 95:
                  iconName = Marker95
                  break
                case 96:
                  iconName = Marker96
                  break
                case 97:
                  iconName = Marker97
                  break
                case 98:
                  iconName = Marker98
                  break
                case 99:
                  iconName = Marker99
                  break

                default:
                  iconName = Marker1
              }
              return (
                <Marker
                  key={index}
                  icon={`${iconName}`}
                  draggable={location.pathname.includes('addpin')}
                  onDragEnd={(e) => onDragEnd(e, index)}
                  position={latLng}
                />
              )
            })}
          </>
        ) : (
          <></>
        )}
        {currentDirection && (
          <DirectionsRenderer
            directions={currentDirection}
            onLoad={(directionsRenderer) => {
              ref.current = directionsRenderer
            }}
            options={{
              draggable: false, // props?.isView ? false : true,
              polylineOptions: {
                strokeColor: '#0000E3',
                strokeWeight: 5,
              },
            }}
            onDirectionsChanged={onDirectionsChanged}
          />
        )}
      </GoogleMap>
    )
  }

  const renderMapWithDeparture = () => {
    return props?.origin === '0,0' || props?.origin.includes('undefined' || props?.marker) || !props.showDirections
      ? renderMapWithoutDirection()
      : renderMapWithDirrection()
  }

  const renderMapWithoutDeparture = () => {
    return (
      <GoogleMap
        center={props?.marker?.lat === 0 ? center : props.marker}
        zoom={props?.zoomLevel}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        onLoad={(map) => setMap(map)}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          styles: mapStyles,
        }}>
        {props?.marker?.lat === 0 ? '' : <Marker position={props.marker} />}
      </GoogleMap>
    )
  }

  return <MapContainerStyleWrapper>{props?.isDeparture === true ? renderMapWithDeparture() : renderMapWithoutDeparture()}</MapContainerStyleWrapper>
}
