// const admin = require('firebase');
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {app} from "../db/firebaseConnection";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCD4DFF2DLW66NYp-vQo_whlEBNqqGOl_Q",
//   authDomain: "alpharoutedev.firebaseapp.com",
//   projectId: "alpharoutedev",
//   storageBucket: "alpharoutedev.appspot.com",
//   messagingSenderId:"987751353761",
//   appId: "1:987751353761:web:4aa0155c2d19dccbbd4fe0",
//   // measurementId: "G-T8VN1YF9BT"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
// const addUser = async (email, password) => {
//   try {
//     console.log("Adding new User");
//     //Adding New User Here with Email And Password
//     return new Promise((resolve, reject) => {
//       firebase
//         .auth()
//         .createUserWithEmailAndPassword(email, password)
//         .then((data) => {
//           resolve({
//             error: false,
//             Status: 201,
//             message: "Record Added succesfully",
//             data: data,
//           });
//         })
//         .catch(function (error) {
//           // Handle Errors here.
//           var errorCode = error.code;
//           var errorMessage = error.message;
//           resolve({
//             error: false,
//             Status: 400,
//             message: "Bad Request",
//             data: errorCode,
//           });
//         });
//     });
//   } catch (error) {
//     return {
//       error: true,
//       Status: 501,
//       message: "Internal Server Error",
//       data: error.message,
//     };
//   }
// };
export const loginUserwithemailandpassword = async (email, password) => {
  try {
    // var email = email ? email : "admin@rei.com";
    // var password = password ? password : "admin123";
    
    // const token = await getAdminAuth().createCustomToken(
    //   credential.user.uid
    // );
    // res.status(200).json({ token })
  //   signInWithEmailAndPassword(auth, email, password)
  // .then((userCredential) => {
  //   // Signed in 
  //   console.log("Success")
  //   const user = userCredential.user;
  //   // ...
  // })
  // .catch((error) => {
  //   const errorCode = error.code;
  //   const errorMessage = error.message;
  //   console.log("failure")
  // });
    //Sign In User with Email and Password

    return new Promise((resolve, reject) => {
      // firebase
      //   .auth()
        signInWithEmailAndPassword(auth,email, password)
        .then((data) => {
          resolve({
            error: false,
            Status: 200,
            message: "Login successful",
            data: data.user.uid,
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          resolve({
            error: false,
            Status: 401,
            message: "Unauthorized",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};

