import { app } from '../db/firebaseConnection'
import { Route } from '../models/route'
import { doc, getFirestore, deleteDoc, where, getDocs, query, collectionGroup, getDoc } from 'firebase/firestore'
import { getStorage, ref, deleteObject } from 'firebase/storage'
const db = getFirestore(app)

export const deleteDeparturepoint = async (id) => {
  try {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < id.length; i++) {
        const routeRef = doc(db, 'Route', id[i])
        const pins = query(collectionGroup(db, 'Pin'), where('routeinfo_id', '==', id[i]))
        getDocs(pins)
          .then(async (data) => {
            data.forEach(async (docs) => {
              const pinRef = doc(db, 'Pin', docs.id)
              const snapshot = await getDoc(pinRef)
              const path = snapshot.data().storagePath
              console.log(path)
              const storage = getStorage()
              const deleteRef = ref(storage, path)
              await deleteObject(deleteRef)
              await deleteDoc(pinRef)
              await deleteDoc(routeRef)
            })
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code
            var errorMessage = error.message
            resolve({
              error: false,
              Status: 401,
              message: 'Departure point deletion failed',
              data: errorCode,
            })
          })
      }
      resolve({
        error: false,
        Status: 200,
        message: 'Records deleted successfully',
        data: '',
      })
    })
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: 'Internal Server Error',
      data: error.message,
    }
  }
}
