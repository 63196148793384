import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import history from "./library/helpers/history";

import "./assets/fonts/RobotoBold.ttf";
import "./assets/fonts/RobotoMedium.ttf";
import "./assets/fonts/RobotoRegular.ttf";

import "./index.css";
import store from "./redux/store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter getUserConfirmation={() => {}} history={history}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

reportWebVitals();
