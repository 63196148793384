import styled from "styled-components";
import WithDirection from "../../library/helpers/rtl";

const AdminHeaderStyleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
`;

export default WithDirection(AdminHeaderStyleWrapper);
