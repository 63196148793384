import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Card, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AdminLayoutStyleWrapper1 } from "../../assets/styles/globalStyle";
import MapContainer from "../../components/MapContainer/MapContainer";
import RouteTitleContainer from "../../components/RouteTitleContainer/RouteTitleContainer";
import AddDestinationPointStyleWrapper from "./AddDestinationPoint.styles";
import FooterButton from "../../components/FooterButton/FooterButton";
import { usePlacesWidget } from "react-google-autocomplete";
import { AppConstant } from "../../config/constant";
import actions from "../../redux/admin/actions";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  BUTTON_SAVE,
  PLACEHOLDER_DESTINATION_NAME,
  PLACEHOLDER_DESTINATION_NAME_EN,
  PLACEHOLDER_DESTINATION_SEARCH,
  PLACEHOLDER_ZIPCODE,
  PLACEHOLDER_ADDRESS,
  TITLE_ADDRESS,
  TITLE_DESTINATION_NAME,
  TITLE_DESTINATION_NAME_EN,
  TITLE_DESTINATION_SEARCH,
  TITLE_ZIPCODE,
} from "../../config/constant/language";

export default function AddDestinationPoint() {
  const antInputRef = useRef(null);
  const { id } = useParams();
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adddestination, getdestination, editdestination } = actions;

  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [destinationLatLng, setDestinationLatLng] = useState({
    lat: 0,
    lng: 0,
  });
  const [addDestinationForm] = Form.useForm();

  let addDestinationSuccess = useSelector(
    (state) => state.Admin.addDestinationSuccess
  );
  let singleDestinationDetail = useSelector(
    (state) => state.Admin.singleDestinationDetail
  );

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getdestination(id));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (location.pathname.includes("edit")) {
      addDestinationForm.setFieldsValue({
        route_name: singleDestinationDetail.route_name,
        destination_name: singleDestinationDetail.destination_japan,
        destination_name_en: singleDestinationDetail.destination_english,
        destination_search: singleDestinationDetail.destination_search,
        postal_code: singleDestinationDetail.postal_code,
        address: singleDestinationDetail.address,
      });
      setPostalCode(singleDestinationDetail.postal_code);
      setAddress(singleDestinationDetail.address);
      setDestinationLatLng({
        lat: singleDestinationDetail.destination_lat,
        lng: singleDestinationDetail.destination_lng,
      });
    }
  }, [singleDestinationDetail]); // eslint-disable-line

  useEffect(() => {
    if (addDestinationSuccess === true) {
      if (location.pathname.includes("edit")) {
        const id = localStorage.getItem("destination_id");
        // console.log('redirecting to view route');
        navigate(`/viewroutedetails/${id}`);
      }else{
        navigate(`/adminroutelist`);

      }
    }
  }, [addDestinationSuccess]); // eslint-disable-line

  let inputEvent;
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place, inputRef, autocompleteRef) => {
      // console.log({place});
      setAddress(place.formatted_address);
      setDestinationLatLng({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      for (var i = 0; i < place.address_components.length; i++) {
        for (var j = 0; j < place.address_components[i].types.length; j++) {
          if (place.address_components[i].types[j] === "postal_code") {
            setPostalCode(place.address_components[i].long_name);
          }
        }
      }
      addDestinationForm.setFieldsValue({
        destination_search: place.formatted_address,
        address: place.formatted_address,
      });
      let postalAddresArray = place.address_components.filter((addressComp) =>
        addressComp.types.includes("postal_code")
      );
      if (postalAddresArray.length > 0) {
        let postalCode = postalAddresArray[0]?.long_name;
        addDestinationForm.setFieldsValue({
          postal_code: postalCode,
        });
      }
    },
    options: {
      types: [],
    },
  });

  const onFinish = (value) => {
    let payload = {
      route_name: value.route_name,
      destination_search: value.destination_search,
      destination_japan: value.destination_name,
      destination_english: value.destination_name_en,
      postal_code: value.postal_code,
      address: value.address,
      destination_lat: destinationLatLng.lat,
      destination_lng: destinationLatLng.lng,
    };
    console.log("payload-----> ", payload);
    if (address === "") {
      notification.error({
        message: "Please select destination.",
        description: "",
      });
      ref.current.focus();
    } else {
      if (location.pathname.includes("edit")) {
        console.log("editpayload-----> ", payload);
        let data = { id: id, payload: payload };
        dispatch(editdestination(data));
      } else {
        console.log("addpayload-----> ", payload);
        dispatch(adddestination(payload));
      }
    }
  };

  return (
    <AdminLayoutStyleWrapper1>
      <Card>
        <div className="left-wrapper">
          <RouteTitleContainer
            formName={addDestinationForm}
            isEditable={true}
          ></RouteTitleContainer>
          <div className="content-container">
            <AddDestinationPointStyleWrapper>
              <Form form={addDestinationForm}>
                <div className="add-destination-form-container">
                  <div>
                    <p>{TITLE_DESTINATION_SEARCH}</p>
                    <Form.Item
                      name="destination_search"
                      rules={[
                        {
                          required: true,
                          message:
                            AppConstant.FormValidation
                              .destinationSearchRequired,
                        },
                      ]}
                      colon={false}
                    >
                      <Input
                        autoComplete="off"
                        placeholder={PLACEHOLDER_DESTINATION_SEARCH}
                        ref={(c) => {
                          antInputRef.current = c;
                          if (c) ref.current = c.input;
                        }}
                      ></Input>
                    </Form.Item>
                  </div>
                  <br />
                  <div>
                    <p>{TITLE_DESTINATION_NAME}</p>
                    <Form.Item
                      name="destination_name"
                      rules={[
                        {
                          required: true,
                          message:
                            AppConstant.FormValidation.destinationNameRequired,
                        },
                      ]}
                      colon={false}
                    >
                      <Input placeholder={PLACEHOLDER_DESTINATION_NAME}></Input>
                    </Form.Item>
                  </div>
                  <div>
                    <p>{TITLE_DESTINATION_NAME_EN}</p>
                    <Form.Item
                      name="destination_name_en"
                      rules={[
                        {
                          required: true,
                          message:
                            AppConstant.FormValidation.destinationNameRequired,
                        },
                      ]}
                      colon={false}
                    >
                      <Input
                        placeholder={PLACEHOLDER_DESTINATION_NAME_EN}
                      ></Input>
                    </Form.Item>
                  </div>
                  <br />
                  <div>
                    <p>{TITLE_ZIPCODE}</p>
                    <Form.Item
                      name="postal_code"
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.zipCodeRequired,
                        },
                      ]}
                      value={postalCode}
                      onChange={inputEvent}
                      colon={false}
                    >
                      <Input placeholder={PLACEHOLDER_ZIPCODE}></Input>
                    </Form.Item>
                    {/* {postalCode === '' ? <p>N/A</p> : <p>{postalCode}</p>} */}
                  </div>
                  <div>
                    <p>{TITLE_ADDRESS}</p>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: AppConstant.FormValidation.addressRequired,
                        },
                      ]}
                      colon={false}
                    >
                      <Input placeholder={PLACEHOLDER_ADDRESS}></Input>
                    </Form.Item>
                    {/* {address === '' ? <p>N/A</p> : <p>{address}</p>} */}
                  </div>
                </div>
              </Form>
              <Form form={addDestinationForm} onFinish={onFinish}>
                <div className="add-destination-save">
                  <Button htmlType="submit">{BUTTON_SAVE}</Button>
                </div>
              </Form>
            </AddDestinationPointStyleWrapper>
          </div>
        </div>
        <div className="right-wrapper">
          <MapContainer marker={destinationLatLng} zoomLevel={12}></MapContainer>
        </div>
      </Card>
      <FooterButton></FooterButton>
    </AdminLayoutStyleWrapper1>
  );
}
