import styled from "styled-components";
import WithDirection from "../../library/helpers/rtl";

const AdminRouteListStyleWrapper = styled.div`
  .number-of-dest-and-route-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    div {
      margin: 5px 0px 5px 40px;
      font-size: 16px;
    }
  }
  .ant-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 0px;
    .ant-card-body {
      display: flex;
      flex-direction: column;
      .table-container {
        width: 100%;
        min-height: 60vh;
        height: 60vh;
        margin: 0px 0px 20px 0px;
        .table-heading-text {
          font-size: 25px;
          margin-bottom: 10px;
        }
        .ant-btn {
          margin: 0px;
        }
      }
      .route-add-delete-button-container {
        .ant-btn {
          margin: 0px 20px 0px 0px;
        }
      }
    }
  }
`;
export default WithDirection(AdminRouteListStyleWrapper);
