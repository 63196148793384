import { app } from "../db/firebaseConnection";
import { Destination } from "../models/destination";
import { collection, getFirestore, getDocs } from "firebase/firestore";
const db = getFirestore(app);

export const getAllDestination = async () => {
  try {
    return new Promise((resolve, reject) => {
      getDocs(collection(db, "Destination"))
        .then((data) => {
          console.log("All Fetched Successfully",data)
          resolve({
            error: false,
            Status: 200,
            message: "Records fetched successfully",
            data: data,
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          resolve({
            error: false,
            Status: 401,
            message: "Documents fetched failed",
            data: errorCode,
          });
        });
    });
  } catch (error) {
    return {
      error: true,
      Status: 501,
      message: "Internal Server Error",
      data: error.message,
    };
  }
};
