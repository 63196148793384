import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { BUTTON_BACK, BUTTON_DELETE_ROUTE, BUTTON_EXIT } from '../../config/constant/language';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { 
  MODAL_CANCEL_BUTTON, 
  MODAL_RETURN_BUTTON, 
  RETURN_MODAL_TEXT ,
  SINGLE_DESTINATION_DELETE_MODAL_TEXT,
  MODAL_DELETE_BUTTON
} from '../../config/constant/language';
import actions from "../../redux/admin/actions";

const { clearDepartureData } = actions;

export default function FooterButton(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  const [returnModal, setReturnModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const onModalDeleteClick = () => {
    const id = localStorage.getItem("destination_id");
    dispatch(actions.deletedestination([id]));
    setDeleteModal(false);
    navigate(`/adminroutelist`);
  };

  const onModalReturnClick = () => {
    // console.log('modal return clicked');
    if (location.pathname.includes('editdepaturepoint')) {
      // console.log('clearing departure data');
      dispatch(clearDepartureData())
      navigate(-1);
    }else if (location.pathname.includes('addpin')) {
      // console.log('redirecting to view departure');
      const id = localStorage.getItem("departure_id");
      navigate(`/viewdepaturepoint/${id}`);
    }else{
      navigate(-1);
    }
  };

  const onReturnButtonClick = () => {
    // console.log('return clicked');
    if (location.pathname.includes('viewdepaturepoint')) {
      const id = localStorage.getItem("destination_id");
      // console.log('redirecting to view route');
      dispatch(clearDepartureData())
      navigate(`/viewroutedetails/${id}`);
    }else if (location.pathname.includes('viewroutedetails')) {
      // console.log('redirecting to admin route');
      navigate(`/adminroutelist`);
    }else if (location.pathname.includes('addpin')){
      setReturnModal(true);
    } else if (location.pathname.includes('view')) {
      // console.log('returning');
      navigate(-1);
    } else {
      setReturnModal(true);
    }
  };
  return (
    <div className='footer-button-wrapper'>
      <Button onClick={onReturnButtonClick}>{BUTTON_BACK}</Button>
      {props.isViewRoutePage ? (
        <Button className='deleteRouteButton' onClick={()=>setDeleteModal(true)}>
          {BUTTON_DELETE_ROUTE}
          {/* <span className='subButtonText'> (可能であればダブルチェック)</span> */}
        </Button>
      ) : (
        ''
        // <Button>{BUTTON_EXIT}</Button>
      )}
      <Modal
        centered
        closable={false}
        visible={returnModal}
        onOk={onModalReturnClick}
        onCancel={() => setReturnModal(false)}
        okText={MODAL_RETURN_BUTTON}
        cancelText={MODAL_CANCEL_BUTTON}
      >
        <h2>{RETURN_MODAL_TEXT}</h2>
      </Modal>
      <Modal
              centered
              closable={false}
              visible={deleteModal}
              onOk={onModalDeleteClick}
              onCancel={() => setDeleteModal(false)}
              okText={MODAL_DELETE_BUTTON}
              cancelText={MODAL_CANCEL_BUTTON}
            >
              <h2>{SINGLE_DESTINATION_DELETE_MODAL_TEXT}</h2>
            </Modal>
    </div>
  );
}
