import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import actions from './actions'
import { addDestination } from '../../library/services/api_services/addDestinationService'
import { getAllDestination } from '../../library/services/api_services/viewAllDestinationService'
import { deleteDestination } from '../../library/services/api_services/deleteDestinationService'
import { getSingleDestination } from '../../library/services/api_services/viewSingleDestinationService'
import { updateDestination } from '../../library/services/api_services/updateDestinationService'
import { addDeparturepoint } from '../../library/services/api_services/addDeparturepointService'
import { getAllDeparturepoint } from '../../library/services/api_services/viewAllDeparturepointService'
import { deleteDeparturepoint } from '../../library/services/api_services/deleteDeparturepointService'
import { getSingleDeparturepoint } from '../../library/services/api_services/viewSingleDeparturepointService'
import { updateDeparturepoint } from '../../library/services/api_services/updateDeparturepointService'
import { fileUploadService } from '../../library/services/api_services/fileUploadService'
import { getAllPinpoint } from '../../library/services/api_services/viewAllPinpointService'
import { deletePinpoint } from '../../library/services/api_services/deletePinpointService'
import { getSinglePinpoint } from '../../library/services/api_services/viewSinglePinpointservice'
import { updatePinpoint } from '../../library/services/api_services/updatePinpointService'
import { getAllDeparturepointcount } from '../../library/services/api_services/viewAllDeparturepointcount'
import { getAlldestinationDeparturepointcount } from '../../library/services/api_services/getAlldestinationDeparturepointcount'

export function* adddestination() {
  // console.log('adding destination ---');
  yield takeEvery(actions.ADD_DESTINATION, function* ({ payload }) {
    try {
      const response = yield call(addDestination, payload)
      if (response.Status === 200) {
        yield put({
          type: actions.ADD_DESTINATION_SUCCESS,
        })
        yield localStorage.setItem('destination_id', response?.data)
      } else {
        yield put({
          type: actions.ADD_DESTINATION_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.ADD_DESTINATION_FAILED,
      })
    }
  })
}

export function* getalldestination() {
  // console.log('getting all destination ---');
  yield put({
    type: actions.START_LOADING,
  })

  yield takeEvery(actions.GET_ALL_DESTINATION, function* () {
    try {
      const responseWithCount = yield call(getAlldestinationDeparturepointcount)
      // const response = yield call(getAllDestination);
      if (responseWithCount.Status === 200) {
        yield put({
          type: actions.STOP_LOADING,
        })
        yield put({
          type: actions.GET_ALL_DESTINATION_SUCCESS,
          // allDestinationDetails: response,
          allDestinationDetailsWithCount: responseWithCount.Status === 200 && responseWithCount.data,
        })
      } else {
        yield put({
          type: actions.GET_ALL_DESTINATION_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_ALL_DESTINATION_FAILED,
      })
    }
  })
}
export function* getalldeparturecount() {
  yield takeEvery(actions.GET_ALL_DESTINATION, function* () {
    try {
      // console.log('getting all departure count ---');
      // const response0 = yield call(getAlldestinationDeparturepointcount);
      const response = yield call(getAllDeparturepointcount)
      // console.log('all departure count ---',response.data.total);
      if (response.Status === 200) {
        yield put({
          type: actions.GET_ALL_DEPARTURE_COUNT_SUCCESS,
          allDepartureCount: response.data.total,
        })
      } else {
        yield put({
          type: actions.GET_ALL_DEPARTURE_COUNT_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_ALL_DEPARTURE_FAILED,
      })
    }
  })
}
export function* deletedestination() {
  // console.log('deleting destination ---');
  yield takeEvery(actions.DELETE_DESTINATION, function* ({ payload }) {
    try {
      const response = yield call(deleteDestination, payload)
      if (response.Status === 200) {
        yield all([
          put({
            type: actions.DELETE_DESTINATION_SUCCESS,
          }),
          put({
            type: actions.GET_ALL_DESTINATION,
          }),
        ])
      } else {
        yield put({
          type: actions.DELETE_DESTINATION_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_DESTINATION_FAILED,
      })
    }
  })
}

export function* getsingledestination() {
  yield takeEvery(actions.GET_SINGLE_DESTINATION, function* ({ payload }) {
    try {
      // console.log('getting single destination ---',payload);
      const response = yield call(getSingleDestination, payload)
      // console.log('got single destination ---',response);
      if (response.Status === 200) {
        // console.log('got single destination success ');
        yield put({
          type: actions.GET_SINGLE_DESTINATION_SUCCESS,
          singleDestinationDetail: response.data,
        })
      } else {
        yield put({
          type: actions.GET_SINGLE_DESTINATION_FAILED,
        })
      }
    } catch (error) {
      console.log({ error })
      yield put({
        type: actions.GET_SINGLE_DESTINATION_FAILED,
      })
    }
  })
}

export function* editdestination() {
  // console.log('editing destination ---');
  yield takeEvery(actions.EDIT_DESTINATION, function* ({ payload }) {
    try {
      const response = yield call(updateDestination, payload.id, payload.payload)
      if (response.Status === 200) {
        yield put({
          type: actions.EDIT_DESTINATION_SUCCESS,
        })
      } else {
        yield put({
          type: actions.EDIT_DESTINATION_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_DESTINATION_FAILED,
      })
    }
  })
}

export function* adddeparture() {
  // console.log('adding departure ---');
  yield takeEvery(actions.ADD_DEPARTURE, function* ({ payload }) {
    try {
      const response = yield call(addDeparturepoint, payload)
      if (response.Status === 201) {
        yield put({
          type: actions.ADD_DEPARTURE_SUCCESS,
        })
        // yield localStorage.setItem('destination_id', response?.data);
      } else {
        yield put({
          type: actions.ADD_DEPARTURE_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.ADD_DEPARTURE_FAILED,
      })
    }
  })
}

export function* getalldeparture() {
  // console.log('getting all departure ---');
  yield put({
    type: actions.START_LOADING,
  })
  yield takeEvery(actions.GET_ALL_DEPARTURE, function* ({ payload }) {
    try {
      const response = yield call(getAllDeparturepoint, payload)
      if (response.Status === 200) {
        yield put({
          type: actions.STOP_LOADING,
        })
        yield put({
          type: actions.GET_ALL_DEPARTURE_SUCCESS,
          allDepartureDetails: response,
        })
      } else {
        yield put({
          type: actions.GET_ALL_DEPARTURE_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_ALL_DEPARTURE_FAILED,
      })
    }
  })
}

export function* deletedeparture() {
  // console.log('deleting departure ---');
  yield takeEvery(actions.DELETE_DEPARTURE, function* ({ payload }) {
    try {
      const response = yield call(deleteDeparturepoint, payload.selectedRoutes)
      if (response.Status === 200) {
        yield all([
          put({
            type: actions.DELETE_DEPARTURE_SUCCESS,
          }),
          put({
            type: actions.GET_ALL_DEPARTURE,
            payload: payload.id,
          }),
        ])
      } else {
        yield put({
          type: actions.DELETE_DEPARTURE_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_DEPARTURE_FAILED,
      })
    }
  })
}

export function* getdeparture() {
  // console.log('getting single departure ---');
  yield takeEvery(actions.GET_SINGLE_DEPARTURE, function* ({ payload }) {
    // console.log('payload', payload);
    try {
      const response = yield call(getSingleDeparturepoint, payload)
      // console.log("response single departure", response);
      if (response.Status === 200) {
        yield put({
          type: actions.GET_SINGLE_DEPARTURE_SUCCESS,
          singleDepartureDetail: response.data,
        })
      } else {
        yield put({
          type: actions.GET_SINGLE_DEPARTURE_SUCCESS,
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_SINGLE_DEPARTURE_FAILED,
      })
    }
  })
}

export function* editdeparture() {
  // console.log('editing departure---');
  yield takeEvery(actions.EDIT_DEPARTURE, function* ({ payload }) {
    try {
      const response = yield call(updateDeparturepoint, payload.id, payload.payload)
      if (response.Status === 200) {
        yield put({
          type: actions.EDIT_DEPARTURE_SUCCESS,
        })
      } else {
        yield put({
          type: actions.EDIT_DEPARTURE_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_DEPARTURE_FAILED,
      })
    }
  })
}

export function* addpin() {
  // console.log('adding pin ---');
  yield takeEvery(actions.ADD_PIN, function* ({ payload }) {
    yield put({
      type: actions.START_LOADING,
    })
    try {
      const response = yield call(fileUploadService, payload)
      // console.log({ payload, response });
      if (response.Status === 201) {
        yield put({
          type: actions.ADD_PIN_SUCCESS,
        })
        yield put({
          type: actions.GET_ALL_PIN,
          payload: payload.routeinfo_id,
        })
        yield put({
          type: actions.STOP_LOADING,
        })
      } else {
        yield put({
          type: actions.ADD_PIN_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.ADD_PIN_FAILED,
      })
      yield put({
        type: actions.STOP_LOADING,
      })
    }
  })
}

export function* getallpin() {
  yield takeEvery(actions.GET_ALL_PIN, function* ({ payload }) {
    try {
      // console.log('getting all pin ---');
      yield put({
        type: actions.START_LOADING,
      })
      const response = yield call(getAllPinpoint, payload)

      if (response.Status === 200) {
        yield put({
          type: actions.STOP_LOADING,
        })
        yield put({
          type: actions.GET_ALL_PIN_SUCCESS,
          allPinDetails: response,
        })
      } else {
        yield put({
          type: actions.GET_ALL_PIN_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.STOP_LOADING,
      })
      yield put({
        type: actions.GET_ALL_PIN_FAILED,
      })
    }
  })
}

export function* deletepin() {
  yield takeEvery(actions.DELETE_PIN, function* ({ payload }) {
    try {
      // console.log('deleting pin ---',payload);
      const response = yield call(deletePinpoint, payload)
      // console.log({ payload });
      if (response.Status === 200) {
        yield all([
          put({
            type: actions.DELETE_PIN_SUCCESS,
          }),
          put({
            type: actions.GET_ALL_PIN,
            payload: payload.id,
          }),
        ])
      } else {
        yield put({
          type: actions.DELETE_PIN_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_PIN_FAILED,
      })
    }
  })
}
export function* getpin() {
  // console.log('getting pin---');
  yield takeEvery(actions.GET_SINGLE_PIN, function* ({ payload }) {
    try {
      const response = yield call(getSinglePinpoint, payload)
      if (response.Status === 200) {
        yield put({
          type: actions.GET_SINGLE_PIN_SUCCESS,
          singlePinDetail: response.data,
        })
      } else {
        yield put({
          type: actions.GET_SINGLE_PIN_SUCCESS,
        })
      }
    } catch (error) {
      yield put({
        type: actions.GET_SINGLE_PIN_FAILED,
      })
    }
  })
}

export function* editpin() {
  yield takeEvery(actions.EDIT_PIN, function* ({ payload }) {
    // const dispatch = useDispatch();
    yield put({
      type: actions.EDIT_PIN_FAILED,
    })
    try {
      // console.log('editing pin ---',payload);
      const response = yield call(updatePinpoint, payload.id, payload.payload)
      // console.log({response});
      if (response.Status === 200) {
        // dispatch(getallpin(payload.id))
        yield put({
          type: actions.EDIT_PIN_SUCCESS,
        })
      } else {
        yield put({
          type: actions.EDIT_PIN_FAILED,
        })
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_PIN_FAILED,
      })
    }
  })
}
export default function* rootSaga() {
  yield all([
    fork(adddestination),
    fork(getalldestination),
    fork(getalldeparturecount),
    fork(deletedestination),
    fork(getsingledestination),
    fork(editdestination),
    fork(adddeparture),
    fork(getdeparture),
    fork(editdeparture),
    fork(getalldeparture),
    fork(deletedeparture),
    fork(addpin),
    fork(getpin),
    fork(editpin),
    fork(getallpin),
    fork(deletepin),
  ])
}
