import {app} from "../db/firebaseConnection";
import {Route} from "../models/route"
import { collection,getFirestore,getDocs,query,collectionGroup ,where} from "firebase/firestore";
const db = getFirestore(app);

export const getAllDeparturepoint = async (destination_id) => {
    try {
      console.log("getAllDeparturepoint");
      return new Promise((resolve, reject) => {
        const museums = query(collectionGroup(db, 'Route'), where('destination_id', '==', destination_id));
    getDocs(museums).then((data) => {
        console.log("Departure point Fetched Successfully",data)
        resolve({
            error: false,
            Status: 200,
            message: "Departure point fetched successfully",
            data: data,
          });
          data.forEach((doc) => {
            console.log(doc.id, ' => ', doc.data());
          });
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("Departure point Fecthed Failure!!!",errorCode,errorMessage)
        resolve({
          error: false,
          Status: 401,
          message: "Departure point fetched failed",
          data: errorCode,
        });
      });
      })
    } catch (error) {
        return {
            error: true,
            Status: 501,
            message: "Internal Server Error",
            data: error.message,
          };
    }
};